import React, { useEffect, useRef } from 'react';
import { Canvas, Rect, Text, Image as FabricImage } from 'fabric';
import { storage } from '../../utils/firebase';
import { ref, getDownloadURL } from 'firebase/storage';

const DesignCanvas = ({ designState, setDesignState }) => {
  const canvasRef = useRef(null);
  const fabricCanvasRef = useRef(null);
  const currentImageRef = useRef(null);

  // Initial canvas setup
  useEffect(() => {
    // Create canvas with specific dimensions
    fabricCanvasRef.current = new Canvas(canvasRef.current, {
      width: 600,
      height: 800,
      backgroundColor: '#f0f0f0',
      preserveObjectStacking: true
    });

    // Make canvas responsive
    const resizeCanvas = () => {
      const container = document.querySelector('.design-canvas-container');
      if (container && fabricCanvasRef.current) {
        const scale = container.offsetWidth / 600;
        fabricCanvasRef.current.setZoom(scale);
        fabricCanvasRef.current.setDimensions({
          width: container.offsetWidth,
          height: 800 * scale
        });
      }
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      fabricCanvasRef.current?.dispose();
    };
  }, []);

  // Update shirt mockup when color changes
  useEffect(() => {
    const loadShirtMockup = async () => {
      if (!fabricCanvasRef.current || !designState.selectedColor) return;

      try {
        const mockupPath = `products/shirts/00606/${designState.selectedColor.name}-front.jpg`;
        const mockupRef = ref(storage, mockupPath);
        const imageUrl = await getDownloadURL(mockupRef);

        const img = new Image();
        img.crossOrigin = 'anonymous';

        img.onload = () => {
          // Remove previous image if it exists
          if (currentImageRef.current) {
            fabricCanvasRef.current.remove(currentImageRef.current);
          }

          const fabricImage = new FabricImage(img, {
            selectable: false,
            evented: false
          });

          // Scale to fit
          const scale = Math.min(
            fabricCanvasRef.current.width / img.width,
            fabricCanvasRef.current.height / img.height
          );
          fabricImage.scale(scale);

          // Clear canvas and add new image
          fabricCanvasRef.current.clear();
          fabricCanvasRef.current.add(fabricImage);
          fabricCanvasRef.current.centerObject(fabricImage);
          
          // Store reference and move to back
          currentImageRef.current = fabricImage;
          fabricImage.moveTo(0);

          // Add print area
          const printArea = new Rect({
            width: 300,
            height: 400,
            fill: 'transparent',
            stroke: '#333333',
            strokeWidth: 2,
            strokeDashArray: [5, 5],
            selectable: false,
            evented: false,
            name: 'printArea'
          });

          const printAreaLabel = new Text('Print Area', {
            fontSize: 14,
            fill: '#666666',
            selectable: false,
            evented: false
          });

          fabricCanvasRef.current.add(printArea);
          fabricCanvasRef.current.add(printAreaLabel);
          fabricCanvasRef.current.centerObject(printArea);
          printAreaLabel.set({
            top: printArea.top - 30,
            left: printArea.left + (printArea.width / 2)
          });
          
          // Render everything
          fabricCanvasRef.current.renderAll();
        };

        img.src = imageUrl;

      } catch (error) {
        console.error('Error in loadShirtMockup:', error);
      }
    };

    loadShirtMockup();
  }, [designState.selectedColor]);

  return (
    <div className="design-canvas-container">
      <canvas ref={canvasRef} />
    </div>
  );
};

export default DesignCanvas; 