import React, { useState } from 'react';
import '../styling/QuantityGrid.css';

const QuantityGrid = ({ quantities, onQuantityChange }) => {
  const sizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL'];
  const [focusedInputs, setFocusedInputs] = useState({});
  
  // Calculate total quantity
  const totalQuantity = Object.values(quantities)
    .reduce((sum, qty) => sum + (Number(qty) || 0), 0);

  // Handle quantity change
  const handleChange = (size, value) => {
    // If the input is empty or just "0", set it to 0
    const newValue = value === '' ? 0 : Math.max(0, parseInt(value) || 0);
    onQuantityChange({
      ...quantities,
      [size]: newValue
    });
  };

  const handleFocus = (size) => {
    setFocusedInputs(prev => ({
      ...prev,
      [size]: true
    }));
  };

  const handleBlur = (size) => {
    setFocusedInputs(prev => ({
      ...prev,
      [size]: false
    }));
    // Only update if needed
    const currentValue = quantities[size];
    if (currentValue === '' || isNaN(currentValue)) {
      onQuantityChange({
        ...quantities,
        [size]: 0
      });
    }
  };

  const getDisplayValue = (size) => {
    if (focusedInputs[size]) {
      return quantities[size] === 0 ? '' : quantities[size];
    }
    return quantities[size];
  };

  return (
    <div className="quantity-grid">
      <div className="size-inputs">
        {sizes.map(size => (
          <div key={size} className="size-column">
            <label htmlFor={`qty-${size}`} className="size-label">{size}</label>
            <input
              id={`qty-${size}`}
              type="number"
              min="0"
              value={getDisplayValue(size)}
              onChange={(e) => handleChange(size, e.target.value)}
              onFocus={() => handleFocus(size)}
              onBlur={() => handleBlur(size)}
              className="quantity-input"
            />
          </div>
        ))}
      </div>
      
      <div className="quantity-total">
        <span className="total-label">Total Quantity:</span>
        <span className="total-value">{totalQuantity}</span>
      </div>
    </div>
  );
};

export default React.memo(QuantityGrid); 