import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
import '../styling/ColorSelection.css';

const ColorSelection = ({ selectedMaterial, selectedColor, onColorSelect }) => {
  const [availableColors, setAvailableColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadColors = async () => {
      if (!selectedMaterial) return;
      
      const styleId = selectedMaterial?.ssStyleId || selectedMaterial;
      
      if (!styleId) {
        console.error('No style ID available:', selectedMaterial);
        setError('Invalid material selection');
        return;
      }
      
      setLoading(true);
      setError(null);
      
      try {
        const colorDoc = await getDoc(doc(db, 'colorSwatches', styleId));
        
        if (colorDoc.exists()) {
          const colorData = colorDoc.data();
          // Sort colors alphabetically by name
          const colors = colorData.colors
            .map((color, index) => ({
              id: `${styleId}-${color.name}-${index}`,
              name: color.name,
              swatchUrl: color.swatchUrl,
              hex: color.swatchTextColor
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          setAvailableColors(colors);
        } else {
          setAvailableColors([]);
        }
      } catch (err) {
        console.error('Error loading colors:', err);
        setError('Failed to load color options');
      } finally {
        setLoading(false);
      }
    };

    loadColors();
  }, [selectedMaterial]);

  // Helper function to check if a color is selected
  const isColorSelected = (color) => {
    if (!selectedColor) return false;
    
    // Compare by ID if possible, otherwise compare by name
    return selectedColor.id === color.id || selectedColor.name === color.name;
  };

  if (!selectedMaterial) {
    return <p className="color-selection-message">Please select a garment first</p>;
  }

  if (loading) {
    return <p className="color-selection-message">Loading available colors...</p>;
  }

  if (error) {
    return <p className="color-selection-message error">Error loading colors: {error}</p>;
  }

  return (
    <div className="color-selection">
      <div className="color-grid horizontal">
        {availableColors.map(color => (
          <button
            key={color.id}
            className={`color-swatch ${isColorSelected(color) ? 'selected' : ''}`}
            onClick={() => onColorSelect(color)}
            style={{ 
              backgroundImage: `url(${color.swatchUrl})`,
              backgroundColor: color.hex 
            }}
            title={color.name}
          >
            <span className="color-name">{color.name}</span>
          </button>
        ))}
      </div>
      
      {/* Display selected color information */}
      {selectedColor && (
        <div className="selected-color-info">
          <p>Selected: {selectedColor.name}</p>
        </div>
      )}
    </div>
  );
};

export default ColorSelection; 