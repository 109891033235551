export const materials = [
  {
    id: 'budget-cotton',
    name: 'Budget 100% Cotton - Gildan 2000',
    description: 'Affordable, durable 100% cotton t-shirt',
    priceIndicator: '$',
    priceMultiplier: 1.0,
    details: {
      weight: '5.3 oz',
      material: '100% Cotton',
      brand: 'Gildan 2000'
    },
    ssStyleId: '00760'
  },
  {
    id: 'premium-cotton',
    name: 'Premium 100% Cotton - Bella+Canvas 3001',
    description: 'Soft, retail-quality 100% cotton t-shirt',
    priceIndicator: '$$',
    priceMultiplier: 1.4,
    details: {
      weight: '4.3 oz',
      material: '100% Cotton',
      brand: 'Bella+Canvas 3001'
    },
    ssStyleId: '00606'
  },
  {
    id: 'budget-blend',
    name: 'Budget 50/50 Blend - Bella+Canvas 3001CVC',
    description: 'Affordable polyester/cotton blend t-shirt',
    priceIndicator: '$$',
    priceMultiplier: 1.3,
    details: {
      weight: '5.5 oz',
      material: '50% Cotton / 50% Polyester',
      brand: 'Bella+Canvas 3001CVC'
    },
    ssStyleId: '00706'
  },
  {
    id: 'premium-blend',
    name: 'Premium Tri-Blend - Bella+Canvas 3413',
    description: 'Ultra-soft poly/cotton/rayon blend t-shirt',
    priceIndicator: '$$$',
    priceMultiplier: 1.8,
    details: {
      weight: '4.2 oz',
      material: '50% Polyester / 25% Cotton / 25% Rayon',
      brand: 'Bella+Canvas 3413'
    },
    ssStyleId: '84706'
  }
]; 