import React from 'react';
import { Link } from 'react-router-dom';
import '../styling/Footer.css';

const Footer = () => {
  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="footer-section contact">
          <h3>Contact Us</h3>
          <address>
            <p>862 42nd Street</p>
            <p>Des Moines, IA 50312</p>
            <p>
              <a href="tel:515-288-2066">515-288-2066</a>
            </p>
            <p>
              <a href="mailto:info@eightsevencentral.com">
                info@eightsevencentral.com
              </a>
            </p>
          </address>
        </div>

        <div className="footer-section links">
          <h3>Quick Links</h3>
          <nav>
            <Link to="/screen-printing">Screen Printing</Link>
            <Link to="/digital-printing">Digital Printing</Link>
            <Link to="/get-quote">Get a Quote</Link>
            <Link to="/screen-printing/order">Order Now</Link>
          </nav>
        </div>

        <div className="footer-section social">
          <h3>Connect With Us</h3>
          <div className="social-links">
            <a 
              href="https://www.instagram.com/87central" 
              target="_blank" 
              rel="noopener noreferrer"
              className="instagram-link"
            >
              @87central
            </a>
          </div>
          <div className="hours">
            <h4>Hours</h4>
            <p>9:30-5:00 Weekdays</p>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Eight Seven Central. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 