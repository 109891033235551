// Calculate the next order cutoff time (1:00 PM Central Time on weekdays)
export const getNextCutoffTime = () => {
  const now = new Date();
  
  // Convert to Central Time
  const centralTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
  
  // Set cutoff time to 1:00 PM today
  const cutoffTime = new Date(centralTime);
  cutoffTime.setHours(13, 0, 0, 0);
  
  // If it's already past 1:00 PM, or it's a weekend, move to next business day
  const dayOfWeek = centralTime.getDay(); // 0 = Sunday, 6 = Saturday
  const isPastCutoff = centralTime > cutoffTime;
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6;
  
  if (isPastCutoff || isWeekend) {
    // Move to next business day
    let daysToAdd = 1;
    
    // If it's Friday after cutoff, add 3 days to get to Monday
    if (dayOfWeek === 5 && isPastCutoff) {
      daysToAdd = 3;
    } 
    // If it's Saturday, add 2 days to get to Monday
    else if (dayOfWeek === 6) {
      daysToAdd = 2;
    }
    // If it's Sunday, add 1 day to get to Monday
    else if (dayOfWeek === 0) {
      daysToAdd = 1;
    }
    
    cutoffTime.setDate(cutoffTime.getDate() + daysToAdd);
  }
  
  return cutoffTime;
};

// Format the time remaining until next cutoff as "HH:MM"
export const formatTimeRemaining = (targetDate) => {
  const now = new Date();
  const timeRemaining = targetDate - now;
  
  // Calculate hours and minutes
  const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
  const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
  
  // Format as HH:MM
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
};

export const formatDate = (dateString) => {
  if (!dateString) return 'Not specified';
  
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric', 
    month: 'long', 
    day: 'numeric'
  });
};

// Update calculateDefaultDate to be more explicit
export const calculateDefaultDate = () => {
  const now = new Date();
  let businessDays = 8;
  
  // Convert to Central Time
  const centralTime = new Date(now.toLocaleString('en-US', { timeZone: 'America/Chicago' }));
  
  // Set cutoff time to 1:00 PM today
  const cutoffTime = new Date(centralTime);
  cutoffTime.setHours(13, 0, 0, 0);
  
  // Check if we're past cutoff or on weekend
  const isPastCutoff = centralTime > cutoffTime;
  const isWeekend = centralTime.getDay() === 0 || centralTime.getDay() === 6;
  
  // Start date calculation
  const date = new Date(centralTime);
  date.setHours(0, 0, 0, 0);
  
  // If past cutoff or weekend, start counting from next business day
  if (isPastCutoff || isWeekend) {
    let daysToAdd = 1;
    
    // If it's Friday after cutoff, add 3 days
    if (centralTime.getDay() === 5 && isPastCutoff) {
      daysToAdd = 3;
    }
    // If it's Saturday, add 2 days
    else if (centralTime.getDay() === 6) {
      daysToAdd = 2;
    }
    // If it's Sunday, add 1 day
    else if (centralTime.getDay() === 0) {
      daysToAdd = 1;
    }
    
    date.setDate(date.getDate() + daysToAdd);
  }
  
  // Now count business days
  while (businessDays > 0) {
    date.setDate(date.getDate() + 1);
    // Skip weekends
    if (date.getDay() !== 0 && date.getDay() !== 6) {
      businessDays--;
    }
  }
  
  return date.toISOString().split('T')[0];
};

// Update calculateRushStatus to handle null/undefined dates
export const calculateRushStatus = (selectedDate) => {
  if (!selectedDate) {
    return { isRush: false, days: 8, type: 'standard' };
  }
  
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  const selected = new Date(selectedDate);
  selected.setHours(0, 0, 0, 0);
  
  let businessDays = 0;
  const tempDate = new Date(today);
  
  // Count business days between today and selected date
  while (tempDate <= selected) {
    if (tempDate.getDay() !== 0 && tempDate.getDay() !== 6) {
      businessDays++;
    }
    tempDate.setDate(tempDate.getDate() + 1);
  }
  
  console.log(`Business days calculated: ${businessDays} for date ${selectedDate}`); // Debug log
  
  if (businessDays >= 8) {
    return { 
      isRush: false, 
      days: businessDays, 
      type: 'standard',
      message: 'Standard processing time'
    };
  } else if (businessDays >= 4) {
    return { 
      isRush: true, 
      days: businessDays, 
      type: 'rush',
      message: 'Rush processing required'
    };
  } else {
    return { 
      isRush: true, 
      days: businessDays, 
      type: 'super-rush',
      message: 'Super rush processing required'
    };
  }
}; 