import React from 'react';
import './OrderDetails.css';

const OrderDetails = ({ designState, setDesignState, onBack }) => {
  const totalQuantity = Object.values(designState.quantities).reduce((sum, qty) => sum + qty, 0);

  return (
    <div className="order-details">
      <h2>Order Details</h2>

      {/* Size Selection */}
      <section className="details-section">
        <h3>Select Quantities</h3>
        <div className="size-grid">
          {Object.entries(designState.quantities).map(([size, qty]) => (
            <div key={size} className="size-input-group">
              <label htmlFor={`size-${size}`}>{size}</label>
              <div className="quantity-controls">
                <button 
                  className="quantity-btn"
                  onClick={() => setDesignState(prev => ({
                    ...prev,
                    quantities: {
                      ...prev.quantities,
                      [size]: Math.max(0, (prev.quantities[size] || 0) - 1)
                    }
                  }))}
                >
                  -
                </button>
                <input
                  id={`size-${size}`}
                  type="number"
                  min="0"
                  value={qty}
                  onChange={(e) => setDesignState(prev => ({
                    ...prev,
                    quantities: {
                      ...prev.quantities,
                      [size]: parseInt(e.target.value) || 0
                    }
                  }))}
                />
                <button 
                  className="quantity-btn"
                  onClick={() => setDesignState(prev => ({
                    ...prev,
                    quantities: {
                      ...prev.quantities,
                      [size]: (prev.quantities[size] || 0) + 1
                    }
                  }))}
                >
                  +
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="total-quantity">
          Total Quantity: {totalQuantity}
        </div>
      </section>

      {/* Delivery Method */}
      <section className="details-section">
        <h3>Delivery Method</h3>
        <div className="delivery-selection">
          <select
            value={designState.deliveryMethod}
            onChange={(e) => setDesignState(prev => ({
              ...prev,
              deliveryMethod: e.target.value
            }))}
          >
            <option value="pickup">Local Pickup</option>
            <option value="shipping">Shipping ($10 flat rate)</option>
          </select>
        </div>
      </section>

      {/* Navigation Buttons */}
      <div className="navigation-buttons">
        <button className="back-button" onClick={onBack}>
          ← Back to Design
        </button>
        <button className="next-button" onClick={() => {/* Handle checkout navigation */}}>
          Proceed to Checkout →
        </button>
      </div>
    </div>
  );
};

export default OrderDetails; 