import React, { useState, useEffect } from 'react';
import '../styling/ScreenPrintingOrder.css';
import MaterialSelection from '../components/MaterialSelection';
import ColorSelection from '../components/ColorSelection';
import QuantityGrid from '../components/QuantityGrid';
import PrintLocations from '../components/PrintLocations';
import ArtworkUpload from '../components/ArtworkUpload';
import DueDateSelection from '../components/DueDateSelection';
import DeliveryMethod from '../components/DeliveryMethod';
import FloatingPriceDisplay from '../components/FloatingPriceDisplay';
import { useNavigate, useLocation } from 'react-router-dom';
import { calculatePricing } from '../utils/priceCalculator';

const ScreenPrintingOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Initialize state for all form fields
  const [material, setMaterial] = useState(null);
  const [quantities, setQuantities] = useState({
    'XS': 0, 'S': 0, 'M': 0, 'L': 0, 'XL': 0, '2XL': 0, '3XL': 0
  });
  const [printLocations, setPrintLocations] = useState({});
  const [designs, setDesigns] = useState({});
  const [deliveryDate, setDeliveryDate] = useState('');
  const [isRushOrder, setIsRushOrder] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [deliveryAddress, setDeliveryAddress] = useState({
    street: '',
    city: '',
    state: '',
    zip: ''
  });
  const [additionalNotes, setAdditionalNotes] = useState('');
  
  // When selecting material, preserve the color selection
  const onMaterialSelect = (selectedMaterial) => {
    // If we're updating the same material, preserve the selected color
    if (material && material.code === selectedMaterial.code && material.selectedColor) {
      setMaterial({
        ...selectedMaterial,
        selectedColor: material.selectedColor
      });
    } else {
      setMaterial(selectedMaterial);
    }
  };
  
  // Handle color selection for the material
  const onColorSelect = (color) => {
    if (material) {
      // Ensure we have all the color data
      const selectedColor = {
        id: color.id,
        name: color.name,
        swatchUrl: color.swatchUrl,
        hex: color.hex
      };
      
      // Log the color selection to verify it's being set properly
      console.log("Selected color:", selectedColor);
      
      setMaterial({
        ...material,
        selectedColor: selectedColor
      });
    }
  };
  
  // Check if we have data coming back from Checkout
  useEffect(() => {
    if (location.state?.orderDetails) {
      const data = location.state.orderDetails;
      
      if (data.material) setMaterial(data.material);
      if (data.quantities) setQuantities(data.quantities);
      if (data.printLocations) setPrintLocations(data.printLocations);
      if (data.designs) setDesigns(data.designs);
      if (data.deliveryDate) setDeliveryDate(data.deliveryDate);
      if (data.isRushOrder !== undefined) setIsRushOrder(data.isRushOrder);
      if (data.deliveryMethod) setDeliveryMethod(data.deliveryMethod);
      if (data.deliveryAddress) setDeliveryAddress(data.deliveryAddress);
      if (data.additionalNotes) setAdditionalNotes(data.additionalNotes);
      
      // Clear the location state
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  // Calculate total quantity
  const totalQuantity = Object.values(quantities).reduce((a, b) => a + b, 0);

  // Calculate default date
  const calculateDefaultDate = () => {
    const today = new Date();
    let businessDays = 8;
    const date = new Date(today);
    
    // Simpler approach: always count 8 full business days from today
    while (businessDays > 0) {
      date.setDate(date.getDate() + 1);
      // Skip weekends
      if (date.getDay() !== 0 && date.getDay() !== 6) {
        businessDays--;
      }
    }
    
    return date.toISOString().split('T')[0];
  };

  // Set default date when component mounts
  useEffect(() => {
    const defaultDate = calculateDefaultDate();
    setDeliveryDate(defaultDate);
  }, []);

  // Calculate if it's a rush order - renamed to avoid conflict with state variable
  const calculateIsRushOrder = () => {
    if (!deliveryDate) return false;
    const today = new Date();
    const selectedDate = new Date(deliveryDate);
    
    // Calculate business days between today and the selected date
    const diffTime = Math.abs(selectedDate - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    // If the delivery date is less than 10 business days away, it's a rush order
    return diffDays < 10;
  };

  // Function to handle proceeding to checkout
  const handleProceedToCheckout = () => {
    const orderDetails = {
      material,
      selectedColor: material?.selectedColor,
      totalQuantity,
      quantities,
      printLocations,
      designs,
      deliveryDate,
      isRushOrder,
      deliveryMethod,
      deliveryAddress,
      additionalNotes,
      pricing: calculatePricing(material, quantities, printLocations, isRushOrder, deliveryMethod)
    };
    
    navigate('/checkout', { state: { orderDetails } });
  };

  return (
    <div className="screen-printing-order">
      <h1>Screen Printing Order Form</h1>
      
      {/* Material Selection Section */}
      <section className="order-section">
        <h2>1. Select Material</h2>
        <MaterialSelection 
          selectedMaterial={material} 
          onMaterialSelect={onMaterialSelect} 
        />
      </section>
      
      {/* Color Selection Section */}
      <section className="order-section">
        <h2>2. Select Color</h2>
        <ColorSelection 
          selectedMaterial={material}
          selectedColor={material?.selectedColor}
          onColorSelect={onColorSelect}
        />
      </section>

      {/* Quantity Grid Section */}
      <section className="order-section">
        <h2>3. Unisex Quantities</h2>
        <QuantityGrid 
          quantities={quantities}
          onQuantityChange={(newQuantities) => 
            setQuantities(newQuantities)
          }
        />
      </section>

      {/* Print Locations Section */}
      <section className="order-section">
        <h2>4. Print Locations</h2>
        <PrintLocations 
          selectedLocations={printLocations}
          onLocationChange={(newLocations) => 
            setPrintLocations(newLocations)
          }
        />
      </section>

      {/* Artwork Upload Section */}
      <section className="order-section">
        <h2>5. Upload Artwork</h2>
        <ArtworkUpload 
          selectedPrintLocations={printLocations}
          onArtworkUpload={(artworkData) => {
            console.log('Artwork upload data received:', artworkData); // Debug log
            
            if (artworkData.type === 'description') {
              // Handle design description
              setDesigns({
                type: 'description',
                description: artworkData.description,
                locations: artworkData.locations
              });
            } else {
              // Handle file uploads (complete or sketch)
              setDesigns({
                type: artworkData.type,
                uploads: artworkData.uploads,
                locations: artworkData.locations
              });
            }
          }}
        />
      </section>

      {/* Due Date Selection Section */}
      <section className="order-section">
        <h2>6. Select Due Date</h2>
        <DueDateSelection 
          selectedDate={deliveryDate}
          onDateChange={(date) => setDeliveryDate(date)}
          defaultDate={calculateDefaultDate()}
        />
      </section>

      {/* Delivery Method Section */}
      <section className="order-section">
        <h2>7. Delivery Method</h2>
        <DeliveryMethod 
          selectedMethod={deliveryMethod}
          onMethodChange={(method) => 
            setDeliveryMethod(method)
          }
          shippingAddress={deliveryAddress}
          onAddressChange={(address) => 
            setDeliveryAddress(address)
          }
        />
      </section>

      {/* Update button text */}
      <div className="button-container" style={{ 
        width: '100%', 
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
        marginBottom: '120px'
      }}>
        <div style={{
          width: '100%',
          maxWidth: '800px',
          padding: '0',
          margin: '0 20px'
        }}>
          <button 
            className="proceed-to-checkout"
            onClick={handleProceedToCheckout}
            style={{
              backgroundColor: '#8BA888',
              color: 'white',
              padding: '15px 30px',
              border: 'none',
              borderRadius: '4px',
              fontSize: '1.1em',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease',
              width: '100%',
              position: 'relative',
              zIndex: 1
            }}
          >
            Proceed to Checkout
          </button>
        </div>
      </div>

      {/* Add extra space at bottom of page */}
      <div style={{ height: '60px' }} /> {/* Additional spacing at bottom */}

      {/* Floating Price Display */}
      <FloatingPriceDisplay 
        material={material || ''}
        quantities={quantities || {
          XS: 0, S: 0, M: 0, L: 0, XL: 0, '2XL': 0, '3XL': 0
        }}
        printLocations={printLocations || {
          frontLarge: false,
          frontSmall: false,
          backLarge: false,
          backSmall: false,
          leftSleeve: false,
          rightSleeve: false
        }}
        isRushOrder={calculateIsRushOrder()}
        deliveryMethod={deliveryMethod || 'pickup'}
      />
    </div>
  );
};

export default ScreenPrintingOrder; 