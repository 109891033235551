import React from 'react';
import ShirtColorSelector from './ShirtColorSelector';
import './DesignTools.css';

const DesignTools = ({ designState, setDesignState, onNext }) => {
  const handleColorSelect = (color) => {
    setDesignState(prev => ({
      ...prev,
      selectedColor: color
    }));
  };

  return (
    <div className="design-tools">
      <h2>Design Your Shirt</h2>
      
      {/* Color Selection */}
      <section className="tool-section">
        <h3>Select Color</h3>
        <ShirtColorSelector 
          selectedColor={designState.selectedColor}
          onColorSelect={handleColorSelect}
        />
      </section>

      {/* Text Tool */}
      <section className="tool-section">
        <h3>Add Text</h3>
        <button className="tool-button">Add New Text</button>
      </section>

      {/* Upload Tool */}
      <section className="tool-section">
        <h3>Upload Artwork</h3>
        <button className="tool-button">Upload Image</button>
      </section>

      <button 
        className="next-button"
        onClick={onNext}
      >
        Select Sizes and Due Date →
      </button>
    </div>
  );
};

export default DesignTools; 