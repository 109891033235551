import React, { useState } from 'react';
import DesignCanvas from './DesignCanvas';
import DesignTools from './DesignTools';
import OrderDetails from './OrderDetails';
import './ShirtDesigner.css';

const ShirtDesigner = () => {
  const [currentPage, setCurrentPage] = useState('design'); // 'design' or 'order'
  const [designState, setDesignState] = useState({
    selectedColor: null,
    textElements: [],
    uploadedArtwork: [],
    quantities: {
      'S': 0,
      'M': 0,
      'L': 0,
      'XL': 0,
      '2XL': 0,
      '3XL': 0
    },
    deliveryMethod: 'pickup',
    deliveryDate: '',
    shippingAddress: {}
  });

  return (
    <div className="shirt-designer">
      <div className="canvas-container">
        <DesignCanvas 
          designState={designState}
          setDesignState={setDesignState}
        />
      </div>
      <div className="sidebar">
        {currentPage === 'design' ? (
          <DesignTools 
            designState={designState}
            setDesignState={setDesignState}
            onNext={() => setCurrentPage('order')}
          />
        ) : (
          <OrderDetails 
            designState={designState}
            setDesignState={setDesignState}
            onBack={() => setCurrentPage('design')}
          />
        )}
      </div>
    </div>
  );
};

export default ShirtDesigner; 