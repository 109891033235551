import React from 'react';
import { Helmet } from 'react-helmet-async';
import '../styling/ScreenPrinting.css';
import { Link } from 'react-router-dom';
// Import images
import colorMatchImage from '../assets/images/color-match.jpg';
import printingImage from '../assets/images/printing-process.jpg';
import garmentWall from '../assets/images/garment-wall.jpg';
// Import video
import processVideo from '../assets/images/400shirts.mov';

const ScreenPrinting = () => {
  return (
    <>
      <Helmet>
        <title>Screen Printing Des Moines | Custom T-Shirts | Eight Seven Central</title>
        <meta 
          name="description" 
          content="Professional screen printing services in Des Moines. High-quality custom t-shirts with 8-day turnaround. Premium brands like AS Colour, Bella+Canvas & more. Get your quote today!"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Screen Printing Services",
            "provider": {
              "@type": "LocalBusiness",
              "name": "Eight Seven Central",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "863 42nd Street",
                "addressLocality": "Des Moines",
                "addressRegion": "IA"
              }
            },
            "areaServed": "Des Moines",
            "description": "Professional screen printing services with 8-day standard turnaround",
            "offers": {
              "@type": "Offer",
              "priceRange": "$10.25-$21.25"
            }
          })}
        </script>
      </Helmet>

      <main className="ScreenPrintingPage">
        <header className="Masthead">
          <h1>SCREEN PRINTING NEWS</h1>
          <p className="Dateline">Special Edition: The Art & Science of Screen Printing</p>
        </header>

        <section className="MainStory Feature">
          <h2>QUALITY GARMENTS MEET PRECISION PRINTING</h2>
          <div className="VideoWrapper">
            <video 
              autoPlay 
              muted 
              loop 
              playsInline
              className="ProcessVideo"
              aria-label="Screen printing process demonstration at Eight Seven Central"
              poster="/path-to-fallback-image.jpg"
            >
              <source src={processVideo} type="video/mp4" />
              <p>Your browser doesn't support HTML5 video. View our process gallery instead.</p>
            </video>
          </div>
          <p>
            In an industry where quality often takes a backseat to speed, Eight Seven Central 
            continues to prove that excellence and efficiency can coexist.
          </p>
          <p>
            Our commitment to quality extends from the first stitch of every garment to the 
            final print that leaves our shop.
          </p>
        </section>

        <section className="ArticleGrid">
          <article className="Feature span-1">
            <h2>THE COLOR REVOLUTION</h2>
            <img 
              src={colorMatchImage} 
              alt="Precise color matching process for screen printing at Eight Seven Central" 
              loading="lazy"
            />
            <p>
              "We don't just print colors – we perfect them. Our commitment to precise color 
              matching means every brand color is exactly as it should be, whether it's on 
              black, white, or any shade in between."
            </p>
            <p>
              The shop's dedication to color excellence has earned them a reputation for 
              handling even the most challenging color combinations with expertise.
            </p>
          </article>

          <article className="Feature span-2">
            <h3>SPEED WITHOUT SACRIFICE</h3>
            <div className="Timeline">
              <div className="TimelineItem">
                <h4>Standard Production</h4>
                <p>8 Business Days</p>
                <span className="TimelineNote">Our standard turnaround time</span>
              </div>
              <div className="TimelineItem">
                <h4>Rush Service</h4>
                <p>4-7 Business Days</p>
                <span className="TimelineNote">+$30 Rush Fee</span>
              </div>
              <div className="TimelineItem">
                <h4>Super Rush Service</h4>
                <p>1-3 Business Days</p>
                <span className="TimelineNote">Fee Varies Based on Order Size</span>
              </div>
            </div>
          </article>

          <article className="Feature span-2">
            <h3>DESIGN SERVICES & ARTWORK OPTIONS</h3>
            <div className="DesignOptions">
              <div className="Option">
                <h4>Custom Design Service</h4>
                <p>
                  Our in-house design team specializes in creating custom artwork that captures your brand's 
                  essence. From concept to final design, we work closely with you to develop artwork that exceeds 
                  expectations.
                </p>
              </div>
              <div className="Option">
                <h4>Print Your Design</h4>
                <p>
                  Have your own artwork? Perfect! We accept print-ready files and will ensure your design is 
                  optimized for the best possible screen printing results. Our pre-press team reviews every file 
                  to guarantee optimal print quality.
                </p>
              </div>
            </div>
            <Link to="/design" className="ReadMore">Learn More About Our Design Services →</Link>
          </article>

          <article className="Feature span-1">
            <h3>PREMIUM BRANDS</h3>
            <div className="BrandGrid">
              <div className="BrandCategory">
                <h4>Featured Brands</h4>
                <ul>
                  <li>AS Colour</li>
                  <li>District</li>
                  <li>Comfort Colors</li>
                  <li>Lane7 Apparel</li>
                </ul>
              </div>
              <div className="BrandCategory">
                <h4>Also Available</h4>
                <ul>
                  <li>Bella + Canvas</li>
                  <li>Next Level</li>
                  <li>Champion</li>
                  <li>And Many More</li>
                </ul>
              </div>
            </div>
          </article>

          <article className="Feature span-3">
            <h3>THE COMPLETE PROCESS</h3>
            <div className="ProcessSteps">
              <div className="Step">
                <h4>1. Choose Your Garment</h4>
                <p>Select from our wide range of high-quality garment options.</p>
              </div>
              
              <div className="Step">
                <h4>2. Select Your Color</h4>
                <p>Pick the perfect garment color for your design from our extensive palette.</p>
              </div>
              
              <div className="Step">
                <h4>3. Finalize Artwork</h4>
                <p>Submit your design or work with our team to create the perfect artwork.</p>
              </div>
              
              <div className="Step">
                <h4>4. Specify Quantities</h4>
                <p>Determine your size breakdown and total quantity needed.</p>
              </div>
              
              <div className="Step">
                <h4>5. Production Time</h4>
                <p>Standard 8-day turnaround, with rush options available as quick as 2 days.</p>
              </div>
            </div>
          </article>
        </section>

        <div className="FeaturedStory">
          <h3 className="SectionHeader">FROM THE NEWSROOM</h3>
          <article className="NewsPreview">
            <h4>Screen Printing Shop Demands Less Attractive UPS Driver After Productivity Drops 40%</h4>
            <p className="Dateline">CHICAGO, IL — Latest Industry News</p>
            <p>
              Employees at Fresh Press Screen Printing have formally requested that UPS "send someone less 
              devastatingly handsome" to deliver their daily shipments after the shop's productivity 
              reportedly plummeted due to prolonged, slack-jawed staring at their new delivery driver, Adrian.
            </p>
            <p>
              "It's a problem. A real problem," said shop owner Greg Henson, who claims the entire operation 
              grinds to a halt the moment the rumble of a UPS truck is heard in the distance...
            </p>
            <Link to="/local-news#ups-driver" className="ReadMore">Continue Reading →</Link>
          </article>
        </div>

        <section className="PricingSection">
          <h2>MARKET RATES</h2>
          <div itemScope itemType="https://schema.org/PriceSpecification">
            <p className="Subtitle">Current Screen Printing Prices</p>
            <div className="PriceGrid">
              <table className="PriceTable">
                <thead>
                  <tr>
                    <th>Quantity</th>
                    <th>1 Color</th>
                    <th>2 Colors</th>
                    <th>3 Colors</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>12-24</td>
                    <td>$16.25</td>
                    <td>$18.75</td>
                    <td>$21.25</td>
                  </tr>
                  <tr>
                    <td>25-49</td>
                    <td>$14.25</td>
                    <td>$16.75</td>
                    <td>$19.25</td>
                  </tr>
                  <tr>
                    <td>50-99</td>
                    <td>$12.25</td>
                    <td>$14.75</td>
                    <td>$17.25</td>
                  </tr>
                  <tr>
                    <td>100+</td>
                    <td>$10.25</td>
                    <td>$12.75</td>
                    <td>$15.25</td>
                  </tr>
                </tbody>
              </table>
              <div className="PriceNotes">
                <p>* Prices based on standard weight shirts (Bella+Canvas 3001 or similar)</p>
                <p>* Additional location prints available</p>
                <p>* Volume discounts available for orders over 500 pieces</p>
              </div>
            </div>
          </div>
          <Link 
            to="/quote-generator" 
            className="CTA"
            aria-label="Get your custom screen printing quote"
          >
            Get Your Custom Quote →
          </Link>
        </section>
      </main>
    </>
  );
};

export default ScreenPrinting; 