import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import ScreenPrinting from './pages/ScreenPrinting';
import ScreenPrintingOrder from './pages/ScreenPrintingOrder';
import DigitalPrinting from './pages/DigitalPrinting';
import Embroidery from './pages/Embroidery';
import Design from './pages/Design';
import Fulfillment from './pages/Fulfillment';
import HighVolumePricing from './pages/HighVolumePricing';
import LocalNews from './pages/LocalNews';
import GarmentInventoryDashboard from './components/GarmentInventoryDashboard';
import Checkout from './pages/Checkout';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import GetQuote from './pages/GetQuote';
import QuoteConfirmation from './pages/QuoteConfirmation';
import CheckoutWithStripe from './pages/CheckoutWithStripe';
import OrderConfirmation from './pages/OrderConfirmation';
import QuoteGenerator from './pages/QuoteGenerator';
import CustomTshirtsLanding from './pages/CustomTshirtsLanding';
import ShirtDesigner from './components/shirtDesigner/ShirtDesigner';
import './styling/Site.css';
import { HelmetProvider } from 'react-helmet-async';

// Google Analytics page tracking component
function PageTracker() {
  const location = useLocation();
  
  useEffect(() => {
    // Track page view on route change
    if (window.gtag) {
      window.gtag('event', 'page_view', {
        page_path: location.pathname + location.search
      });
    }
  }, [location]);
  
  return null;
}

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <PageTracker />
        <div className="App">
          <Navbar />
          <main className="App-main">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/screen-printing" element={<ScreenPrinting />} />
              <Route path="/screen-printing/order" element={<ScreenPrintingOrder />} />
              <Route path="/checkout" element={<CheckoutWithStripe />} />
              <Route path="/order-confirmation" element={<OrderConfirmation />} />
              <Route path="/digital-printing" element={<DigitalPrinting />} />
              <Route path="/embroidery" element={<Embroidery />} />
              <Route path="/design" element={<Design />} />
              <Route path="/fulfillment" element={<Fulfillment />} />
              <Route path="/high-volume-pricing" element={<HighVolumePricing />} />
              <Route path="/local-news" element={<LocalNews />} />
              <Route path="/garment-inventory" element={<GarmentInventoryDashboard />} />
              <Route path="/get-quote" element={<GetQuote />} />
              <Route path="/quote-confirmation" element={<QuoteConfirmation />} />
              <Route path="/quote-generator" element={<QuoteGenerator />} />
              <Route path="/shirt-designer" element={<ShirtDesigner />} />
              <Route path="/custom-tshirts-des-moines" element={<CustomTshirtsLanding />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
