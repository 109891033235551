import React from 'react';
import '../styling/DeliveryMethod.css';

const DeliveryMethod = ({ selectedMethod, shippingAddress, onMethodChange, onAddressChange }) => {
  const deliveryMethods = [
    {
      id: 'pickup',
      title: 'Free In-store Pickup',
      description: '863 42nd Street, Des Moines, Iowa'
    },
    {
      id: 'local',
      title: 'Free Delivery inside Des Moines Metro',
      description: 'Please allow 2 additional days for delivery'
    },
    {
      id: 'shipping',
      title: 'Nationwide Shipping',
      description: 'Shipping costs will be determined once the shirts have been printed and boxed up, but typical costs are $30 per 72 shirts'
    }
  ];

  const handleMethodChange = (value) => {
    console.log('Selected method:', value);
    onMethodChange(value);
  };

  return (
    <div className="delivery-method">
      <div className="delivery-options">
        {deliveryMethods.map((method) => (
          <label key={method.id} className="delivery-option">
            <input
              type="radio"
              name="deliveryMethod"
              value={method.id}
              checked={selectedMethod === method.id}
              onChange={(e) => handleMethodChange(e.target.value)}
            />
            <div className="option-content">
              <span className="option-title">{method.title}</span>
              <span className="option-description">{method.description}</span>
            </div>
          </label>
        ))}
      </div>

      {selectedMethod === 'shipping' && (
        <div className="shipping-address">
          <h3>Shipping Address</h3>
          <div className="address-form">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  value={shippingAddress.name || ''}
                  onChange={(e) => onAddressChange({...shippingAddress, name: e.target.value})}
                  placeholder="John Doe"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group">
                <label htmlFor="street">Street Address</label>
                <input
                  type="text"
                  id="street"
                  value={shippingAddress.street || ''}
                  onChange={(e) => onAddressChange({...shippingAddress, street: e.target.value})}
                  placeholder="123 Main St"
                />
              </div>
            </div>

            <div className="form-row three-columns">
              <div className="form-group">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  id="city"
                  value={shippingAddress.city || ''}
                  onChange={(e) => onAddressChange({...shippingAddress, city: e.target.value})}
                  placeholder="City"
                />
              </div>

              <div className="form-group">
                <label htmlFor="state">State</label>
                <input
                  type="text"
                  id="state"
                  value={shippingAddress.state || ''}
                  onChange={(e) => onAddressChange({...shippingAddress, state: e.target.value})}
                  placeholder="State"
                />
              </div>

              <div className="form-group">
                <label htmlFor="zip">ZIP Code</label>
                <input
                  type="text"
                  id="zip"
                  value={shippingAddress.zip || ''}
                  onChange={(e) => onAddressChange({...shippingAddress, zip: e.target.value})}
                  placeholder="12345"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(DeliveryMethod); 