export const localNewsArticles = [
  {
    id: 'ups-driver',
    headline: 'Screen Printing Shop Demands Less Attractive UPS Driver After Productivity Drops 40%',
    dateline: 'Des Moines, IA',
    excerpt: `Employees at Fresh Press Screen Printing have formally requested that UPS "send someone less 
    devastatingly handsome" to deliver their daily shipments after the shop's productivity reportedly 
    plummeted due to prolonged, slack-jawed staring at their new delivery driver, Adrian.`,
    fullText: `Des Moines, IA — Employees at Fresh Press Screen Printing have formally requested that UPS "send someone less devastatingly handsome" to deliver their daily shipments after the shop's productivity reportedly plummeted due to prolonged, slack-jawed staring at their new delivery driver, Adrian.

"We used to get orders out on time. Now we're all just standing here watching him lift boxes like it's some kind of cologne commercial," said shop owner Greg Henson, "and I'm as bad as anyone."

According to multiple sources, Adrian first appeared on the route three weeks ago, stepping out of his truck with perfectly tousled hair, a jawline that could cut film, and a slightly distressed uniform that clings just right. Since then, the entire operation grinds to a halt the moment the rumble of a UPS truck is heard in the distance.

"I tried to focus on reclaiming screens, but then I looked over and saw him casually adjusting his gloves," said print tech Lisa Tran, staring vacantly into the distance. "I blacked out after that... it's a problem. A real problem."

Employees have allegedly been finding excuses to linger near the loading dock, volunteering to sign for packages they aren't responsible for, and even placing unnecessary supply orders just to ensure his return. "Yesterday, Olivia asked if we needed more emulsion while we were actively restocking it. And I caught Ryan pretending to mop while Adrian unloaded boxes," Henson said, going on to add that "Ryan never mops, so that was a bit of a silver lining."

At press time, the shop had received an email from UPS customer service stating that "driver assignments are non-negotiable" and that they "will not be honoring requests for 'someone who looks more like Steve Buscemi instead.'"`,
    category: 'satire'
  },
  {
    id: 'osha-smell',
    headline: 'OSHA Declares Screen Printing Shop\'s Horrible Smell "Technically Not Illegal"',
    dateline: 'Des Moines, IA',
    excerpt: `After numerous employee complaints about the air inside Inked Up Custom Prints smelling like 
    "burnt plastic mixed with an old gym sock," OSHA inspectors visited the shop Thursday, only to conclude 
    that while the smell was indeed "deeply upsetting," it did not technically violate any safety regulations.`,
    fullText: `Des Moines, IA — After numerous employee complaints about the air inside Inked Up Custom Prints smelling like "burnt plastic mixed with an old gym sock," OSHA inspectors visited the shop Thursday, only to conclude that while the smell was indeed "deeply upsetting," it did not technically violate any safety regulations.

"It's awful. It's truly awful," said OSHA representative Linda Marsh, her eyes watering as she stood near the conveyor dryer. "But after extensive testing, we can confirm that this particular cocktail of plastisol fumes, degreaser, and whatever is rotting in the break room fridge does not exceed federal limits for workplace hazards. Somehow."

Shop employees were less than thrilled with the ruling, many of whom claim they've become "nose-blind" to the stench after prolonged exposure. "One time I left work and my roommate asked if I had been inhaling melted tires all day," said printer Jake Miller. "I thought he was joking, but when I went to light up a cigarette, my shirt immediately combusted."

Despite the lack of an official violation, OSHA did issue a "strongly worded suggestion" that the shop open a window "for the love of God." Meanwhile, the shop's owner, Greg Henson, dismissed concerns, stating, "It's not like this is shaving off the good years of your life"

As of press time, employees were seen huddling around the back door, taking turns huffing fresh air.`,
    category: 'satire'
  },
  {
    id: 'slacker-opinion',
    headline: 'Opinion: I\'m Not Naming Names, But Someone In This Shop Isn\'t Pulling Their Weight',
    dateline: 'Des Moines, IA',
    excerpt: `Look, I'm not here to start drama. I'm just saying that on this small, tight-knit team where 
    everyone's hard work matters, somebody—and I won't say who—seems to be contributing a whole lot less than everyone else.`,
    fullText: `Look, I'm not here to start drama. I'm just saying that on this small, tight-knit team where everyone's hard work matters, somebody—and I won't say who—seems to be contributing a whole lot less than everyone else.

Now, I'm not accusing anyone outright. Maybe some of us are just really good at finding reasons to step away the moment a 200-piece order needs to be stacked. Maybe some of us have mastered the art of looking busy while actually just standing near the press with a clipboard, nodding thoughtfully at nothing. Maybe—just maybe—there's one person in this shop whose primary contribution is sighing loudly and saying, "Man, we are slammed today," before disappearing for 30 minutes.

And hey, I get it. Screen printing is a tough job. But while the rest of us are reclaiming screens, cleaning squeegees, and getting absolutely drenched in ink and degreaser, there's definitely one individual who somehow remains mysteriously clean and dry all shift long. Strange, isn't it?

Anyway, if anyone needs me, I just remembered I need to check on something in the break room.`,
    category: 'satire'
  },
  {
    id: 'designer-revision',
    headline: 'Shirt Designer One Revision Away From Walking Into The Sea',
    dateline: 'Des Moines, IA',
    excerpt: `Local graphic designer Matt Reynolds was reportedly pushed to the brink of sanity Tuesday after 
    his client, Dave's Custom Landscaping, requested their 11th revision to what was once a simple two-color T-shirt design.`,
    fullText: `Des Moines, IA — Local graphic designer Matt Reynolds was reportedly pushed to the brink of sanity Tuesday after his client, Dave's Custom Landscaping, requested their 11th revision to what was once a simple two-color T-shirt design. The shirt design (if it can even still be named as such) now sports six different fonts, three clip art eagles, and what appears to be a JPEG of a business card stretched beyond recognition.

"I don't know what they want anymore," said Reynolds, staring blankly at his computer screen. "Every time I send a proof, they say, 'make it more like that one shirt from 2003 that everyone loved."

The project, which began two weeks ago with a straightforward request for a clean, modern logo on a black tee, has since spiraled into chaos, with the client insisting that the final design be "classy but fun," "bold yet subtle," and somehow include "a rustic American flag, but not in a political way."

"I suggested simplifying the layout, and they sent back a photo of a Monster Energy can with the note, 'Something like this.'"

Reynolds' co-worker, Lisa Tran expressed concern for the situation, "Up until last month, Matt has always been the life of the shop. He's always telling jokes and knows everyones' birthdays by heart. But a couple weeks ago the light went out of his eyes."

Shop owner Greg Henson agreed, adding, "It's like he's died inside."

At press time, the client had just emailed asking if Reynolds could "Make it pop more."`,
    category: 'satire'
  },
  {
    id: 'ink-spill',
    headline: 'Tiny Plastisol Ink Spill Now Everywhere, Officials Confirm',
    dateline: 'Des Moines, IA',
    excerpt: `What began as a dime-sized drop of plastisol ink on local screen printer Kyle Benson's hand has 
    now reportedly spread to every conceivable surface in Inkspire Prints, with employees confirming they have 
    "absolutely no idea how this happened so fast."`,
    fullText: `Des Moines, IA — What began as a dime-sized drop of plastisol ink on local screen printer Kyle Benson's hand has now reportedly spread to every conceivable surface in Inkspire Prints, with employees confirming they have "absolutely no idea how this happened so fast."

"I thought I wiped it off," said Benson, shaking his head. "But then I saw it on my squeegee, then the light switch, then somehow the bathroom door handle. And now it's on the fridge? How did it get on the fridge?"

Despite desperate efforts to contain the spread, the ink was later spotted on the exposure unit, two keyboards, a roll of tape, and, inexplicably, inside a sandwich in the break room.

"I wasn't even near the press today," said graphic designer Olivia Tran, staring in horror at the plastisol smudge now adorning her tablet screen. "This is how it happens, right? This is how we all go?"

At press time shop owner Greg Henson had called for an emergency shutdown to locate the spill's "ground-zero," though efforts have been hampered by a foggy haze that also does not appear to be going away.`,
    category: 'satire'
  },
  {
    id: 'drunk-ethan',
    headline: 'Opinion: I\'m Pretty Sure Ethan Is Drunk Right Now',
    dateline: 'OPINION',
    excerpt: `Look, I don't want to jump to conclusions, but based on the way Ethan just tried to load a screen 
    upside down and then laughed at it for a full 30 seconds, I'm about 98% sure he's hammered at this very moment.`,
    fullText: `Look, I don't want to jump to conclusions, but based on the way Ethan just tried to load a screen upside down and then laughed at it for a full 30 seconds, I'm about 98% sure he's hammered at this very moment.

Now, I get it—screen printing is a tough job, and it's not like we don't knock back the occasional Coors Light during a long run of shirts. But usually, people wait until after their shift to start slurring their words and calling the flash dryer their "warm little buddy."

The first red flag was when he poured a fresh gallon of ink directly onto a T-shirt instead of into the squeegee well, and when I said, "Ethan, what the hell?" he just nodded solemnly and said, "Art." That was when I started taking mental notes.

At one point, he high-fived me for "absolutely crushing it" while I was literally just taping off a screen. And then he high-fived Olivia for absolutely crushing it as she sorted a box of blank shirts. He also high fived Greg for crushing it when he got off the phone with his son's school because there had been some sort of incident.

And when I walked past his workstation, I caught the distinct smell of cheap whiskey. Either that or someone in here spilled an entire bottle of "Old Tavern Special Reserve" into the reclaim tank.

Management hasn't said anything, but I think they know. Greg just sighed when Ethan called the conveyor dryer a "big hot snake" and watched him for a solid minute before walking away in silence.

Anyway, I'm not saying we should do anything drastic, but maybe someone should casually ask Ethan how many fingers they're holding up. And if he says "blue," we shut down production immediately.`,
    category: 'satire'
  }
];

export const businessArticles = [
  {
    id: 'summer-pricing',
    headline: 'Local Print Shop Announces Summer Pricing Special',
    excerpt: 'Eight Seven Central reveals competitive pricing for summer orders...',
    callToAction: 'View Our Pricing →',
    link: '/quote-request-form'
  },
  {
    id: 'rush-orders',
    headline: 'Need It Fast? Rush Order Options Available',
    excerpt: 'From standard 8-day production to super rush 2-day service...',
    callToAction: 'Start Your Order →',
    link: '/screen-printing/order'
  },
  {
    id: 'design-services',
    headline: 'In-House Design Team Ready to Bring Your Ideas to Life',
    excerpt: 'Professional design services available for all printing needs...',
    callToAction: 'Learn About Design Services →',
    link: '/design'
  }
];

export const getRandomArticle = () => {
  const randomIndex = Math.floor(Math.random() * localNewsArticles.length);
  return localNewsArticles[randomIndex];
};

export const getRandomBusinessArticles = (count = 3) => {
  const shuffled = [...businessArticles].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
}; 