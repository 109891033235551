import { initializeApp } from 'firebase/app';
import { getFirestore, doc, setDoc, collection, Timestamp } from 'firebase/firestore';
import { getAuth, signInAnonymously } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCViSd7O4rIhZVOSnOchTOZwSO94vXpA5U",
  authDomain: "eightseven-website.firebaseapp.com",
  projectId: "eightseven-website",
  storageBucket: "eightseven-website.firebasestorage.app",
  messagingSenderId: "885332794534",
  appId: "1:885332794534:web:ac6fa6010ee8b35acb6867",
  measurementId: "G-BJS30B4JD6"
};

// Add more detailed logging
console.log("Initializing Firebase with config:", {
  projectId: firebaseConfig.projectId,
  authDomain: firebaseConfig.authDomain,
  storageBucket: firebaseConfig.storageBucket
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

// Initialize Firestore with settings
const db = getFirestore(app);

// Initialize Auth
const auth = getAuth(app);

// Initialize Storage with logging
const storage = getStorage(app);
console.log("Storage bucket after initialization:", storage.app.options.storageBucket);

// Sign in anonymously
signInAnonymously(auth)
  .then(() => {
    console.log('Signed in anonymously');
  })
  .catch((error) => {
    console.error('Anonymous auth error:', error);
  });

// Function to set up initial data structure
const setupInitialData = async () => {
  try {
    const testDoc = doc(db, 'test', 'connection');
    await setDoc(testDoc, {
      timestamp: Timestamp.now(),
      test: 'Connection test'
    });
    console.log('Successfully wrote test document to Firestore');
  } catch (error) {
    console.error('Error setting up initial data:', error);
  }
};

if (process.env.NODE_ENV === 'development') {
  setupInitialData();
}

export { db, auth, storage, functions };
export default app; 