import React from 'react';

const Fulfillment = () => {
  return (
    <div className="Fulfillment">
      <h2>Fulfillment</h2>
      <p>Our specialty is helping those who have bitten off more than they can chew. If ordering shirts and getting them into people's hands sounds overwhelming, let us give you a hand.</p>

      <h3>Pre-sales Sites</h3>
      <p>No more spreadsheets for you. Let us collect orders and payment from each customer. Then we'll ship each shirt directly to each person.</p>

      <h3>Ongoing Partnerships</h3>
      <p>We'll keep a stock of your merchandise and ship it out as needed. You'll hardly have to do a thing.</p>

      <h3>Get in Touch</h3>
      <p>If you have any questions or need assistance, feel free to reach out to us at <a href="mailto:info@eightsevencentral.com">info@eightsevencentral.com</a>.</p>
    </div>
  );
};

export default Fulfillment; 