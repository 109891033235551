import React from 'react';
import { Link } from 'react-router-dom';
import '../styling/HighVolumePricing.css';

const HighVolumePricing = () => {
  return (
    <div className="HighVolumePricingPage">
      <div className="Masthead">
        <h1>BULK ORDER BULLETIN</h1>
        <p className="Dateline">Special Report: High Volume Custom Apparel Solutions</p>
      </div>

      <div className="MainStory Feature">
        <h2 className="Headline">LARGE ORDERS, SMALL PRICES</h2>
        <p className="LeadParagraph">
          When it comes to large quantities, Eight Seven Central delivers exceptional value without 
          sacrificing quality or speed. Our high-volume pricing structure is designed to make bulk orders 
          more affordable while maintaining our commitment to excellence.
        </p>
        
        <div className="PricingFormula">
          <h3>THE BULK ORDER EQUATION</h3>
          <div className="Formula">
            <span>Garment Cost</span>
            <span>+</span>
            <span>$2.25 per location</span>
            <span>+</span>
            <span>$0.25 per color</span>
          </div>
          <p className="FormulaNotes">
            For orders exceeding 1,000 pieces, this simplified formula helps us deliver 
            maximum value for high-volume needs.
          </p>
        </div>
      </div>

      <div className="ArticleGrid">
        <article className="Feature span-2">
          <h3>CONTACT US DIRECTLY</h3>
          <p className="Emphasis">
            Planning a large order? Skip the online forms and contact us directly for personalized service and pricing.
          </p>
          <div className="ContactOptions">
            <div className="ContactOption">
              <h4>CALL US</h4>
              <p className="ContactDetail">515-288-2066</p>
              <p className="ContactHours">Monday-Friday, 9:30am - 5:00pm</p>
            </div>
            <div className="ContactOption">
              <h4>EMAIL ANYTIME</h4>
              <p className="ContactDetail">zach@eightsevencentral.com</p>
              <p className="ContactHours">24/7 - We'll respond within one business day</p>
            </div>
          </div>
        </article>

        <article className="Feature span-1">
          <h3>STILL FAST</h3>
          <p>
            Don't expect delays just because it's a large order. Our standard production timeline of 6-8 business 
            days typically applies to bulk orders as well. Our production facilities are equipped to handle 
            high-volume projects efficiently.
          </p>
        </article>

        <article className="Feature span-1">
          <h3>FREE LOCAL DELIVERY</h3>
          <p>
            Stop worrying about how to transport hundreds of boxes. With every high-volume order, we provide 
            complimentary local delivery service. We'll bring your entire order directly to your doorstep at 
            no additional charge.
          </p>
        </article>
      </div>

      <div className="BottomCTA">
        <h3>READY TO DISCUSS YOUR BULK ORDER?</h3>
        <div className="CTAButtons">
          <a href="tel:5152882066" className="CTA">Call Now: 515-288-2066</a>
          <a href="mailto:zach@eightsevencentral.com" className="CTA">Email: zach@eightsevencentral.com</a>
        </div>
      </div>
    </div>
  );
};

export default HighVolumePricing; 