import React, { useState, useEffect } from 'react';
import '../styling/FloatingPriceDisplay.css';
import { calculatePricing } from '../utils/priceCalculator';
import { getNextCutoffTime, formatTimeRemaining } from '../utils/dateUtils';

const FloatingPriceDisplay = ({ material, quantities, printLocations, isRushOrder, deliveryMethod }) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  
  // Set up the countdown timer
  useEffect(() => {
    const updateCountdown = () => {
      const nextCutoff = getNextCutoffTime();
      setTimeRemaining(formatTimeRemaining(nextCutoff));
    };
    
    // Update immediately and then every minute
    updateCountdown();
    const intervalId = setInterval(updateCountdown, 60000);
    
    return () => clearInterval(intervalId);
  }, []);
  
  // Calculate total quantity with proper number conversion
  const totalQuantity = Object.values(quantities)
    .reduce((sum, qty) => sum + (Number(qty) || 0), 0);
  
  // Check if at least one print location is selected
  const hasSelectedPrintLocation = printLocations && 
    Object.values(printLocations).some(selected => selected === true);
  
  // Only calculate pricing if we have all required values INCLUDING at least one print location
  const pricing = totalQuantity > 0 && material && hasSelectedPrintLocation
    ? calculatePricing(material, quantities, printLocations || {}, isRushOrder, deliveryMethod)
    : null;

  // For debug - log the calculated pricing
  useEffect(() => {
    if (pricing) {
      console.log("FloatingPriceDisplay pricing:", pricing);
    }
  }, [pricing]);

  // Add debug logging for material
  useEffect(() => {
    if (material) {
      console.log('FloatingPriceDisplay Material:', {
        material,
        ssStyleId: material.ssStyleId,
        name: material.name
      });
    }
  }, [material]);

  // Don't render anything if we don't have valid pricing or no print location selected
  if (!pricing || !pricing.subtotal || !hasSelectedPrintLocation) return null;

  return (
    <div className="floating-price-display">
      <div className="price-content">
        <div className="quantity-summary">
          {totalQuantity} {totalQuantity === 1 ? 'shirt' : 'shirts'}
        </div>
        {timeRemaining && (
          <div className="order-deadline-countdown">
            ⏱️ Order in the next <strong>{timeRemaining}</strong> to ensure delivery by your selected date
          </div>
        )}
        <div className="price-summary">
          <div className="base-price">
            <span className="per-item">${pricing.perItem?.toFixed(2) || '0.00'}/shirt</span>
            <span className="subtotal">Subtotal: ${pricing.subtotal?.toFixed(2) || '0.00'}</span>
          </div>
          <div className="additional-fees">
            {pricing.rushFee > 0 && (
              <div className="fee-item">
                <span className="fee-label">Rush Fee:</span>
                <span className="fee-amount">+${pricing.rushFee?.toFixed(2) || '0.00'}</span>
              </div>
            )}
            {pricing.shippingCost > 0 && (
              <div className="fee-item">
                <span className="fee-label">Shipping:</span>
                <span className="fee-amount">+${pricing.shippingCost?.toFixed(2) || '0.00'}</span>
              </div>
            )}
            <div className="fee-item">
              <span className="fee-label">Sales Tax (7%):</span>
              <span className="fee-amount">+${pricing.salesTax?.toFixed(2) || '0.00'}</span>
            </div>
          </div>
          <div className="total-line">
            <span className="total-price">Total: ${pricing.total?.toFixed(2) || '0.00'}</span>
          </div>
        </div>
        <div className="price-note">
          * Estimated price based on current selections
        </div>
      </div>
    </div>
  );
};

export default FloatingPriceDisplay; 