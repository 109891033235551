import React from 'react';
import '../styling/Design.css';

const Design = () => {
  return (
    <div className="DesignPage">
      <div className="Masthead">
        <h1>DESIGN DEPARTMENT DISPATCH</h1>
        <p className="Dateline">Where Creativity Meets Craftsmanship</p>
      </div>

      <div className="MainStory Feature">
        <h3>DESIGN THAT DEMANDS ATTENTION</h3>
        <div className="ImagePlaceholder">
          <div className="PlaceholderText">Image Coming Soon: Recent Award-Winning Designs</div>
        </div>
        <p className="LeadParagraph">
          We are a print shop that takes design seriously. Whether it's for your family reunion 
          or a startup in need of a brand identity, we bring the same level of dedication and 
          expertise to every project.
        </p>
      </div>

      <div className="ArticleGrid">
        <article className="Feature span-2">
          <h3>AWARD WINNING DESIGN</h3>
          <p>
            Not to brag, but over the years we've won quite a few awards for our designs and branding. 
            Our team's commitment to excellence has been recognized across multiple industries and competitions.
          </p>
          <div className="AwardsList">
            <div className="Award">
              <h4>AIGA Design Excellence</h4>
              <p>2023 - Brand Identity</p>
            </div>
            <div className="Award">
              <h4>Print Magazine Awards</h4>
              <p>2022 - T-Shirt Design</p>
            </div>
            <div className="Award">
              <h4>Local ADDYs</h4>
              <p>2021 - Campaign Series</p>
            </div>
          </div>
        </article>

        <article className="Feature span-1">
          <h3>THE PROCESS</h3>
          <p>
            Great design takes time. Our process is thorough and collaborative, ensuring 
            your vision comes to life exactly as you imagined – or better.
          </p>
          <div className="ProcessSteps">
            <div className="Step">
              <h4>1. Consultation</h4>
              <p>Understanding your needs</p>
            </div>
            <div className="Step">
              <h4>2. Concept</h4>
              <p>Initial design directions</p>
            </div>
            <div className="Step">
              <h4>3. Refinement</h4>
              <p>Perfecting the details</p>
            </div>
            <div className="Step">
              <h4>4. Final Artwork</h4>
              <p>Ready for production</p>
            </div>
          </div>
        </article>
      </div>

      <div className="ContactSection">
        <h3>START YOUR PROJECT</h3>
        <p>
          Ready to bring your vision to life? Our design team is here to help.
          Shoot us an email and one of our designers will be in touch:
        </p>
        <a href="mailto:design@eightsevencentral.com" className="CTA Primary">
          design@eightsevencentral.com
        </a>
      </div>
    </div>
  );
};

export default Design; 