import React from 'react';
import { 
  calculateRushStatus, 
  getNextCutoffTime, 
  formatTimeRemaining,
  formatDate,
  calculateDefaultDate 
} from '../utils/dateUtils';
import '../styling/DueDateSelection.css';

const DueDateSelection = ({ selectedDate, onDateChange }) => {
  const standardDate = calculateDefaultDate();
  const rushStatus = calculateRushStatus(selectedDate);
  const nextCutoff = getNextCutoffTime();
  const timeUntilCutoff = formatTimeRemaining(nextCutoff);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    onDateChange(newDate);
  };

  return (
    <div className="date-selection-container">
      <div className="date-input-container">
        <div className="date-picker">
          <label htmlFor="pickup-date">Select Pickup Date:</label>
          <input
            id="pickup-date"
            type="date"
            value={selectedDate || ''}
            min={new Date().toISOString().split('T')[0]}
            onChange={handleDateChange}
            className="date-input"
          />
        </div>
      </div>

      {/* Rush Status Messages */}
      {rushStatus.type === 'super-rush' && (
        <div className="warning-message super-rush">
          ⚠️ Super Rush Not Available Online: For delivery in 1-3 business days, 
          please contact us directly at (651) 387-3661 or email info@eightsevencentral.com.
        </div>
      )}

      {rushStatus.type === 'rush' && (
        <div className="warning-message rush">
          ⚠️ Rush Fee Applies: A $30 rush fee will be applied because your selected date 
          is {rushStatus.days} business days from today (4-7 business day rush service).
        </div>
      )}

      <div className="standard-time">
        Standard turnaround time: {formatDate(standardDate)} (8 business days from today)
      </div>

      <div className="cutoff-info">
        <div>⏱️ Order in the next {timeUntilCutoff} to ensure delivery by your selected date</div>
        <div>Daily order cutoff is 1:00 PM Central Time</div>
      </div>
    </div>
  );
};

// Add memoization
export default React.memo(DueDateSelection); 