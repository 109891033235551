import React from 'react';

const Embroidery = () => {
  return (
    <div className="Embroidery">
      <h2>Embroidery</h2>
      <p>Embroidery is a great way to expand your brand and maintain a level of professionalism, especially for certain garments that cannot be screen printed.</p>
      <p>While we don't actually do embroidery in-house, we maintain trade relationships with several other fantastic embroidery vendors and can help turn your initial idea into your final product.</p>
      <p>Send us an email to get started: <a href="mailto:info@eightsevencentral.com">info@eightsevencentral.com</a>.</p>
    </div>
  );
};

export default Embroidery; 