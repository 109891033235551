import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styling/FloatingQuote.css';  // We can reuse most styles
import { calculatePricing } from '../utils/priceCalculator';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../utils/firebase';  // Update this import path if needed

const FloatingQuote = React.memo(({ 
  material, 
  quantities, 
  printLocations, 
  isRushOrder, 
  deliveryMethod,
  onQuoteSubmit,
  onPurchaseClick,
  designs,
  deliveryDate,
  artworkData,
  pricing,
  discountedPricing
}) => {
  const navigate = useNavigate();
  
  const [contactInfo, setContactInfo] = useState({ name: '', email: '' });
  
  const totalQuantity = Object.values(quantities)
    .reduce((sum, qty) => sum + (Number(qty) || 0), 0);
  
  const hasSelectedPrintLocation = printLocations && 
    Object.values(printLocations).some(selected => selected === true);

  // Add debug log for artworkData changes
  useEffect(() => {
    console.log('FloatingQuote received new artworkData:', artworkData);
  }, [artworkData]);

  const completionStatus = useMemo(() => {
    const status = {
      garment: !!material,
      color: !!(material?.selectedColor),
      quantities: totalQuantity > 0,
      artwork: !!artworkData && (
        Object.entries(printLocations)
          .filter(([_, isSelected]) => isSelected)
          .every(([location]) => 
            artworkData.uploads?.[location] || 
            (artworkData.type === 'description' && artworkData.description)
          )
      ),
      printLocations: hasSelectedPrintLocation,
      dueDate: !!deliveryDate,
      shipping: !!deliveryMethod
    };
    
    // Add debug log for completion status changes
    console.log('Completion status updated:', status);
    return status;
  }, [
    material, 
    totalQuantity, 
    artworkData,
    printLocations, 
    deliveryDate, 
    deliveryMethod,
    hasSelectedPrintLocation
  ]);

  const allComplete = Object.values(completionStatus).every(status => status);

  // Calculate the discounted price properly
  const calculatedDiscountedPricing = useMemo(() => {
    if (!pricing) return null;

    const discountAmount = pricing.subtotal * 0.1; // 10% off subtotal only
    
    return {
      ...pricing,
      discount: discountAmount,
      total: pricing.total - discountAmount // Apply discount to final total
    };
  }, [pricing]);

  // Update quote submission to use sessionStorage
  const handleQuoteSubmit = async () => {
    if (!contactInfo.name || !contactInfo.email) {
      alert('Please provide your name and email address');
      return;
    }

    try {
      const sendDetailedQuoteRequest = httpsCallable(functions, 'sendDetailedQuoteRequest');
      
      const quoteData = {
        name: contactInfo.name,
        email: contactInfo.email,
        quantity: totalQuantity,
        pickupDate: deliveryDate,
        projectDetails: `
          Material: ${material?.name || 'Not selected'}
          Color: ${material?.selectedColor?.name || 'Not selected'}
          Quantities: ${Object.entries(quantities)
            .filter(([_, qty]) => qty > 0)
            .map(([size, qty]) => `${size}: ${qty}`)
            .join(', ')}
          Print Locations: ${Object.entries(printLocations)
            .filter(([_, isSelected]) => isSelected)
            .map(([location]) => location)
            .join(', ')}
          Delivery Method: ${deliveryMethod}
          Rush Order: ${isRushOrder ? 'Yes' : 'No'}
          
          Pricing Details:
          Per Item: $${pricing.perItem.toFixed(2)}
          Subtotal: $${pricing.subtotal.toFixed(2)}
          ${pricing.rushFee ? `Rush Fee: $${pricing.rushFee.toFixed(2)}` : ''}
          ${pricing.shippingCost ? `Shipping: $${pricing.shippingCost.toFixed(2)}` : ''}
          Sales Tax: $${pricing.salesTax.toFixed(2)}
          Total: $${pricing.total.toFixed(2)}
        `,
        pricing: pricing
      };

      const result = await sendDetailedQuoteRequest(quoteData);
      
      if (result.data.success) {
        // Save quote details to sessionStorage instead of navigation state
        sessionStorage.setItem('quoteDetails', JSON.stringify({
          name: contactInfo.name,
          email: contactInfo.email,
          quoteData
        }));
        
        navigate('/quote-confirmation');
        setContactInfo({ name: '', email: '' });
      } else {
        throw new Error('Failed to send quote request');
      }
    } catch (error) {
      console.error('Error sending quote request:', error);
      alert('There was an error sending your quote request. Please try again or contact us directly.');
    }
  };

  // Simplify purchase click handler
  const handlePurchaseClick = () => {
    if (onPurchaseClick) {
      onPurchaseClick();
    }
  };

  if (!pricing || !pricing.subtotal || !hasSelectedPrintLocation) return null;

  return (
    <div className="floating-quote">
      <div className="price-content">
        <div className="quote-options">
          <div className="regular-quote">
            <div className="price-summary">
              <div className="base-price">
                <span className="per-item">${pricing.perItem?.toFixed(2) || '0.00'}/shirt</span>
                <span className="subtotal">Subtotal: ${pricing.subtotal?.toFixed(2) || '0.00'}</span>
              </div>
              <div className="additional-fees">
                {pricing.rushFee > 0 && (
                  <span className="fee-item">
                    +${pricing.rushFee?.toFixed(2) || '0.00'} rush fee
                  </span>
                )}
                {pricing.shippingCost > 0 && (
                  <span className="fee-item">
                    +${pricing.shippingCost?.toFixed(2) || '0.00'} shipping
                  </span>
                )}
                <span className="fee-item">
                  +${pricing.salesTax?.toFixed(2) || '0.00'} tax
                </span>
              </div>
              <div className="total-line">
                Total: ${pricing.total?.toFixed(2) || '0.00'}
              </div>
              <div className="contact-fields">
                <input
                  type="text"
                  placeholder="Your Name"
                  value={contactInfo.name}
                  onChange={(e) => setContactInfo({...contactInfo, name: e.target.value})}
                  className="contact-input"
                />
                <input
                  type="email"
                  placeholder="Email Address"
                  value={contactInfo.email}
                  onChange={(e) => setContactInfo({...contactInfo, email: e.target.value})}
                  className="contact-input"
                />
              </div>
              <button 
                className="quote-button"
                onClick={handleQuoteSubmit}
              >
                Submit Quote Request
              </button>
              <p className="follow-up-note">
                We'll get in touch with you within a few business hours.
              </p>
            </div>
          </div>

          <div className="or-divider">
            <span>OR</span>
          </div>

          <div className="discounted-quote highlight">
            <div className="price-summary">
              <div className="completion-checklist">
                <h4>Order Progress:</h4>
                <ul>
                  <li className={completionStatus.garment ? 'complete' : 'incomplete'}>
                    {completionStatus.garment ? '✓' : '○'} Garment Selection
                  </li>
                  <li className={completionStatus.color ? 'complete' : 'incomplete'}>
                    {completionStatus.color ? '✓' : '○'} Color Selection
                  </li>
                  <li className={completionStatus.quantities ? 'complete' : 'incomplete'}>
                    {completionStatus.quantities ? '✓' : '○'} Quantities
                  </li>
                  <li className={completionStatus.printLocations ? 'complete' : 'incomplete'}>
                    {completionStatus.printLocations ? '✓' : '○'} Print Locations
                  </li>
                  <li className={completionStatus.artwork ? 'complete' : 'incomplete'}>
                    {completionStatus.artwork ? '✓' : '○'} Artwork Upload
                    {!completionStatus.artwork && 
                      <span className="status-note">(Required)</span>
                    }
                  </li>
                  <li className={completionStatus.dueDate ? 'complete' : 'incomplete'}>
                    {completionStatus.dueDate ? '✓' : '○'} Due Date
                  </li>
                  <li className={completionStatus.shipping ? 'complete' : 'incomplete'}>
                    {completionStatus.shipping ? '✓' : '○'} Shipping Method
                  </li>
                </ul>
                {!allComplete && (
                  <p className="completion-message">
                    {!completionStatus.artwork ? 
                      "You're so close! Upload your artwork and save 10% by ordering now!" :
                      "Complete the remaining items above to place your order!"}
                  </p>
                )}
              </div>
              <button 
                className="purchase-button two-line"
                onClick={handlePurchaseClick}
                disabled={!allComplete}
              >
                <span>Buy now and save 10%!</span>
                <span>Discounted Total: ${calculatedDiscountedPricing.total?.toFixed(2) || '0.00'}</span>
              </button>
              <p className="follow-up-note reassurance">
                Don't worry, we'll send you a mock-up and you'll have plenty of time to review your order prior to printing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}, (prevProps, nextProps) => {
  // Update the comparison function to include artworkData
  const artworkDataChanged = JSON.stringify(prevProps.artworkData) !== JSON.stringify(nextProps.artworkData);
  
  return (
    prevProps.pricing?.total === nextProps.pricing?.total &&
    prevProps.discountedPricing?.total === nextProps.discountedPricing?.total &&
    prevProps.isRushOrder === nextProps.isRushOrder &&
    prevProps.deliveryDate === nextProps.deliveryDate &&
    prevProps.material?.selectedColor?.name === nextProps.material?.selectedColor?.name &&
    prevProps.totalQuantity === nextProps.totalQuantity &&
    JSON.stringify(prevProps.printLocations) === JSON.stringify(nextProps.printLocations) &&
    prevProps.deliveryMethod === nextProps.deliveryMethod &&
    !artworkDataChanged
  );
});

export default FloatingQuote; 