import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { getRandomArticle } from '../data/localNewsArticles';
import '../styling/DigitalPrinting.css';
import digitalprintingheader from '../assets/images/digitalprintingheader.mov';

const DigitalPrinting = () => {
  const featuredArticle = getRandomArticle();
  
  return (
    <>
      <Helmet>
        <title>Digital T-Shirt Printing Des Moines | Eight Seven Central</title>
        <meta 
          name="description" 
          content="Professional digital t-shirt printing in Des Moines. Perfect for small runs, photos, and complex designs. Local pickup available with fast turnaround times. Get an instant quote today!"
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Service",
            "name": "Digital T-Shirt Printing",
            "provider": {
              "@type": "LocalBusiness",
              "name": "Eight Seven Central",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "863 42nd Street",
                "addressLocality": "Des Moines",
                "addressRegion": "IA"
              }
            },
            "areaServed": "Des Moines",
            "description": "Professional digital t-shirt printing services with fast turnaround times",
            "offers": {
              "@type": "Offer",
              "priceRange": "$16-$33"
            }
          })}
        </script>
      </Helmet>
      <main className="DigitalPrintingPage">
        <header className="Masthead">
          <h1>DIGITAL PRINTING TIMES | Custom T-Shirts Des Moines</h1>
          <p className="Dateline">Latest Edition: Professional Digital T-Shirt Printing in Des Moines</p>
        </header>

        <section className="MainStory Feature">
          <h3>THE DIGITAL PRINTING REVOLUTION IN DES MOINES</h3>
          <div className="VideoWrapper">
            <video 
              autoPlay 
              muted 
              loop 
              playsInline
              className="ProcessVideo"
              aria-label="Digital t-shirt printing process demonstration at Eight Seven Central in Des Moines"
              poster="/path-to-fallback-image.jpg"
            >
              <source src={digitalprintingheader} type="video/mp4" />
              <track 
                kind="descriptions" 
                src="/path-to-video-description.vtt" 
                label="Video Description"
              />
            </video>
          </div>
          <p>
            Experience the future of custom apparel at our Des Moines print shop with state-of-the-art 
            Direct-to-Garment (DTG) printing. Perfect for photographs, small runs, and designs 
            with unlimited colors - all available right here in central Iowa. For larger orders, 
            check out our <Link to="/screen-printing">screen printing services</Link>.
          </p>
        </section>

        <article className="Feature PricingSection">
          <h3>PERFECT FOR LOCAL BUSINESSES AND EVENTS</h3>
          <div className="ApplicationList">
            <div className="Application">
              <h4>📸 PHOTOGRAPHS</h4>
              <p>Ideal for photo reproductions and Des Moines memorial shirts</p>
            </div>
            <div className="Application">
              <h4>🎨 COMPLEX DESIGNS</h4>
              <p>No limit on colors or gradients for your custom shirts</p>
            </div>
            <div className="Application">
              <h4>⚡ QUICK LOCAL TURNAROUND</h4>
              <p>Fast production times for Des Moines area orders</p>
            </div>
            <div className="Application">
              <h4>🎯 SMALL QUANTITIES</h4>
              <p>Cost-effective custom t-shirts for orders under 12 pieces</p>
            </div>
          </div>
        </article>

        <div className="PricingSection">
          <h3>DES MOINES DTG PRINTING RATES</h3>
          <p className="Subtitle">Current Digital T-Shirt Printing Prices</p>
          <div className="PriceGrid">
            <table className="PriceTable">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>One Side</th>
                  <th>Two Sides</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1-4 pieces</td>
                  <td>$28.00</td>
                  <td>$33.00</td>
                </tr>
                <tr>
                  <td>5-12 pieces</td>
                  <td>$20.00</td>
                  <td>$25.00</td>
                </tr>
                <tr>
                  <td>13+ pieces</td>
                  <td>$16.00</td>
                  <td>$21.00</td>
                </tr>
              </tbody>
            </table>
            <div className="PriceNotes">
              <p>* Prices based on premium 100% cotton garments</p>
              <p>* Full color included in price - perfect for complex designs</p>
              <p>* Maximum print size: 16"w x 20"h</p>
              <p>* Convenient pickup at our Des Moines location</p>
            </div>
          </div>
        </div>

        <div className="ThreeColumnSection">
          <article className="Feature">
            <h3>PRINT SPECIFICATIONS</h3>
            <div className="SpecsList">
              <div className="SpecItem">
                <h4>Maximum Print Area</h4>
                <p>16" width x 20" height for your custom design</p>
              </div>
              <div className="SpecItem">
                <h4>Print Quality</h4>
                <p>Professional photo-quality detail on every shirt</p>
              </div>
              <div className="SpecItem">
                <h4>Best Materials</h4>
                <p>Premium 100% cotton garments for optimal printing</p>
              </div>
            </div>
          </article>

          <article className="Feature">
            <h3>ARTWORK REQUIREMENTS</h3>
            <div className="SpecsList">
              <div className="SpecItem">
                <h4>Preferred Formats</h4>
                <p>EPS, PDF, PSD, AI for best results</p>
              </div>
              <div className="SpecItem">
                <h4>Resolution</h4>
                <p>300 DPI for crystal-clear custom prints</p>
              </div>
              <div className="SpecItem">
                <h4>Design Help Available</h4>
                <p>Our Des Moines design team is here to help</p>
              </div>
            </div>
          </article>

          <article className="Feature">
            <h3>FROM THE NEWSROOM</h3>
            <div className="NewsPreview">
              <h4>{featuredArticle.headline}</h4>
              <p className="Dateline">{featuredArticle.dateline}</p>
              <p>{featuredArticle.excerpt}</p>
              <Link to={`/local-news#${featuredArticle.id}`} className="ReadMore">Continue Reading →</Link>
            </div>
          </article>
        </div>

        <div className="ActionSections">
          <div className="QuoteSection fullWidth">
            <h3>GET YOUR CUSTOM T-SHIRT QUOTE</h3>
            <p>
              Ready to start your custom t-shirt project in Des Moines? Tell us about your needs,
              and we'll provide a detailed quote within one business day. Local pickup available
              at our convenient Des Moines location.
            </p>
            <Link to="/quote-generator" className="CTA">Request Your Custom Quote →</Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default DigitalPrinting; 