// Base material costs
const BASE_COSTS = {
  '00760': 2.50,    // Gildan 2000 (Budget Cotton)
  '00606': 4.00,    // Bella+Canvas 3001 (Premium Cotton)
  '00706': 4.25,    // Bella+Canvas 3001CVC (Budget Blend)
  '84706': 6.00     // Bella+Canvas 3413 (Premium Blend)
};

// Print costs based on quantity ranges - this is the PER SHIRT print cost
const PRINT_COSTS = {
  '1-2': 22.00,    // $17.75 per shirt
  '3-5': 19.00,    // $16.75 per shirt
  '6-11': 13.00,   // $14.75 per shirt
  '12-23': 12.00,         // $12.75 per shirt
  '24-49': 10.00,         // $10.75 per shirt
  '50-99': 8.00,          // $8.75 per shirt
  '100-199': 6.00,        // $6.75 per shirt
  '200-299': 5.00,        // $5.75 per shirt
  '300+': 4.50            // $5.25 per shirt
};

const LOCATION_COST = 2.25;  // Additional cost per location after the first
const RUSH_FEE = 30.00;      // Flat fee for rush orders
const SALES_TAX = 0.07;      // Iowa sales tax 7%

// Helper function for shipping costs
const calculateShippingCost = (deliveryMethod, quantity) => {
  if (!deliveryMethod || deliveryMethod === 'pickup') {
    return 0;
  } else if (deliveryMethod === 'local') {
    return 15; // Local delivery fee
  } else if (deliveryMethod === 'shipping') {
    if (quantity <= 12) {
      return 12;
    } else if (quantity <= 48) {
      return 20;
    } else {
      return 30;
    }
  }
  return 0;
};

export const calculatePricing = (material, quantities, printLocations, isRushOrder, deliveryMethod) => {
  // Calculate total quantity
  let totalQuantity = 0;
  if (typeof quantities === 'object') {
    totalQuantity = Object.values(quantities).reduce((sum, qty) => sum + (Number(qty) || 0), 0);
  } else {
    totalQuantity = Number(quantities) || 0;
  }
  
  // Determine the material cost per shirt
  let materialCost = 4.00; // Default if not found
  if (material && typeof material === 'object' && material.ssStyleId) {
    materialCost = BASE_COSTS[material.ssStyleId] || materialCost;
  } else if (material && typeof material === 'string') {
    materialCost = BASE_COSTS[material] || materialCost;
  }
  
  // Determine the print cost per shirt based on quantity
  let printCost = 12.75; // Default to single tier
  if (totalQuantity >= 300) {
    printCost = PRINT_COSTS['300+'];
  } else if (totalQuantity >= 200) {
    printCost = PRINT_COSTS['200-299'];
  } else if (totalQuantity >= 100) {
    printCost = PRINT_COSTS['100-199'];
  } else if (totalQuantity >= 50) {
    printCost = PRINT_COSTS['50-99'];
  } else if (totalQuantity >= 24) {
    printCost = PRINT_COSTS['24-49'];
  } else if (totalQuantity >= 12) {
    printCost = PRINT_COSTS['12-23'];
  } else if (totalQuantity >= 6) {
    printCost = PRINT_COSTS['6-11'];
  } else if (totalQuantity >= 3) {
    printCost = PRINT_COSTS['3-5'];
  } else if (totalQuantity >= 1) {
    printCost = PRINT_COSTS['1-2'];
  }
  
  // Calculate additional location fees
  let locationFees = 0;
  if (printLocations && typeof printLocations === 'object') {
    const selectedLocations = Object.values(printLocations).filter(Boolean).length;
    const additionalLocations = Math.max(selectedLocations - 1, 0);
    locationFees = additionalLocations * LOCATION_COST;
  }
  
  // Calculate per-item price (explicitly show the components)
  const perItem = materialCost + printCost + locationFees;
  
  // Calculate subtotal
  const subtotal = perItem * totalQuantity;
  
  // Add rush fee if applicable
  const rushFee = isRushOrder ? RUSH_FEE : 0;
  
  // Add shipping cost
  const shippingCost = calculateShippingCost(deliveryMethod, totalQuantity);
  
  // Calculate tax
  const taxableAmount = subtotal + rushFee + shippingCost;
  const salesTax = taxableAmount * SALES_TAX;
  
  // Calculate total
  const total = taxableAmount + salesTax;
  
  return {
    perItem,
    subtotal,
    rushFee,
    shippingCost,
    salesTax,
    total
  };
};

// New function specifically for shirt designer
export const calculateShirtDesignerPricing = (quantities, isRushOrder, deliveryMethod) => {
  // Calculate total quantity
  const totalQuantity = Object.values(quantities).reduce((sum, qty) => sum + (Number(qty) || 0), 0);
  
  // Use fixed material cost for Bella+Canvas 3001 (00606)
  const materialCost = BASE_COSTS['00606']; // $5.00
  
  // Determine the print cost per shirt based on quantity
  let printCost = 12.75; // Default to single tier
  if (totalQuantity >= 300) {
    printCost = PRINT_COSTS['300+'];
  } else if (totalQuantity >= 200) {
    printCost = PRINT_COSTS['200-299'];
  } else if (totalQuantity >= 100) {
    printCost = PRINT_COSTS['100-199'];
  } else if (totalQuantity >= 50) {
    printCost = PRINT_COSTS['50-99'];
  } else if (totalQuantity >= 24) {
    printCost = PRINT_COSTS['24-49'];
  } else if (totalQuantity >= 12) {
    printCost = PRINT_COSTS['12-23'];
  } else if (totalQuantity >= 6) {
    printCost = PRINT_COSTS['6-11'];
  } else if (totalQuantity >= 3) {
    printCost = PRINT_COSTS['3-5'];
  } else if (totalQuantity >= 1) {
    printCost = PRINT_COSTS['1-2'];
  }
  
  // Calculate per-item price (front location only, no additional locations)
  const perItem = materialCost + printCost;
  
  // Calculate subtotal
  const subtotal = perItem * totalQuantity;
  
  // Add rush fee if applicable
  const rushFee = isRushOrder ? RUSH_FEE : 0;
  
  // Add shipping cost
  const shippingCost = calculateShippingCost(deliveryMethod, totalQuantity);
  
  // Calculate tax
  const taxableAmount = subtotal + rushFee + shippingCost;
  const salesTax = taxableAmount * SALES_TAX;
  
  // Calculate total
  const total = taxableAmount + salesTax;
  
  return {
    perItem,
    subtotal,
    rushFee,
    shippingCost,
    salesTax,
    total
  };
}; 