import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from './firebase';
import { doc, setDoc, Timestamp } from 'firebase/firestore';

const SS_API_KEY = process.env.REACT_APP_SS_API_KEY; // We'll need to add this to your .env file
const SS_API_BASE_URL = 'https://api.ssactivewear.com/v2';

// Function to fetch inventory for a specific style
const fetchStyleInventory = async (styleId) => {
  try {
    // Use the working format we discovered: B{styleId}004
    const sku = `B${styleId}004`;
    const response = await fetch(`${SS_API_BASE_URL}/inventory/${sku}`, {
      headers: {
        'Authorization': `Basic ${SS_API_KEY}`,  // Changed from Bearer to Basic
        'Accept': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error(`SS API Error: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching from SS API:', error);
    throw error;
  }
};

// Function to update Firestore with new inventory data
const updateFirestoreInventory = async (styleId, inventoryData) => {
  try {
    console.log(`Starting Firestore update for style ${styleId}`);
    const garmentDoc = doc(db, 'garments', styleId);
    
    // Transform inventory data into our Firestore structure
    const colors = {};
    
    inventoryData.forEach(item => {
      const colorCode = item.color.code;
      if (!colors[colorCode]) {
        colors[colorCode] = {
          name: item.color.name,
          inventory: {},
          lastUpdated: Timestamp.now()
        };
      }
      colors[colorCode].inventory[item.size] = item.quantity;
    });

    console.log('Transformed data for Firestore:', colors);

    // Update Firestore
    await setDoc(garmentDoc, {
      colors,
      lastUpdated: Timestamp.now()
    }, { merge: true });

    console.log(`Successfully updated Firestore for style ${styleId}`);
  } catch (error) {
    console.error('Error updating Firestore:', error);
    throw error;
  }
};

// Function to sync inventory through Firebase Functions
export const syncStyleInventory = async (styleId) => {
  try {
    console.log(`Starting sync for style ${styleId}`);
    
    // Get Firebase Functions instance
    const functions = getFunctions();
    const syncFunction = httpsCallable(functions, 'syncInventory');
    
    // Call the function
    const result = await syncFunction({ styleId });
    console.log('Received data from Firebase Function:', result.data);
    
    if (result.data.success) {
      await updateFirestoreInventory(styleId, result.data.data);
      console.log(`Completed sync for style ${styleId}`);
      return true;
    } else {
      throw new Error(result.data.error || 'Sync failed');
    }
    
  } catch (error) {
    console.error(`Failed to sync style ${styleId}:`, error);
    throw error;
  }
};

// Function to sync multiple styles
export const syncMultipleStyles = async (styleIds) => {
  const results = {
    successful: [],
    failed: []
  };

  for (const styleId of styleIds) {
    try {
      await syncStyleInventory(styleId);
      results.successful.push(styleId);
    } catch (error) {
      results.failed.push({ styleId, error: error.message });
    }
  }

  return results;
}; 