import React from 'react';
import '../styling/PrintLocations.css';

const PrintLocations = ({ selectedLocations, onLocationChange }) => {
  const handleLocationChange = (location) => {
    const newLocations = { ...selectedLocations };

    // Handle front locations (mutually exclusive)
    if (location === 'frontLarge' || location === 'frontSmall') {
      // If clicking the already selected option, deselect it
      if (selectedLocations[location]) {
        newLocations[location] = false;
      } else {
        // Deselect the other front option and select this one
        newLocations.frontLarge = false;
        newLocations.frontSmall = false;
        newLocations[location] = true;
      }
    }
    
    // Handle back locations (mutually exclusive)
    else if (location === 'backLarge' || location === 'backSmall') {
      // If clicking the already selected option, deselect it
      if (selectedLocations[location]) {
        newLocations[location] = false;
      } else {
        // Deselect the other back option and select this one
        newLocations.backLarge = false;
        newLocations.backSmall = false;
        newLocations[location] = true;
      }
    }
    
    // Handle sleeve locations (can select both)
    else if (location === 'leftSleeve' || location === 'rightSleeve') {
      newLocations[location] = !selectedLocations[location];
    }

    onLocationChange(newLocations);
  };

  return (
    <div className="print-locations">
      <div className="location-column">
        <h3>Front</h3>
        <div className="location-options">
          <label className="location-option">
            <input
              type="checkbox"
              checked={selectedLocations.frontLarge}
              onChange={() => handleLocationChange('frontLarge')}
            />
            <span className="checkbox-label">Large Centered</span>
          </label>
          <label className="location-option">
            <input
              type="checkbox"
              checked={selectedLocations.frontSmall}
              onChange={() => handleLocationChange('frontSmall')}
            />
            <span className="checkbox-label">Small Left Chest</span>
          </label>
        </div>
      </div>

      <div className="location-column">
        <h3>Back</h3>
        <div className="location-options">
          <label className="location-option">
            <input
              type="checkbox"
              checked={selectedLocations.backLarge}
              onChange={() => handleLocationChange('backLarge')}
            />
            <span className="checkbox-label">Large Centered</span>
          </label>
          <label className="location-option">
            <input
              type="checkbox"
              checked={selectedLocations.backSmall}
              onChange={() => handleLocationChange('backSmall')}
            />
            <span className="checkbox-label">Small Back Nape</span>
          </label>
        </div>
      </div>

      <div className="location-column">
        <h3>Sleeve</h3>
        <div className="location-options">
          <label className="location-option">
            <input
              type="checkbox"
              checked={selectedLocations.leftSleeve}
              onChange={() => handleLocationChange('leftSleeve')}
            />
            <span className="checkbox-label">Small Left Sleeve</span>
          </label>
          <label className="location-option">
            <input
              type="checkbox"
              checked={selectedLocations.rightSleeve}
              onChange={() => handleLocationChange('rightSleeve')}
            />
            <span className="checkbox-label">Small Right Sleeve</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default PrintLocations; 