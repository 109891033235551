import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styling/Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="Navbar" aria-label="Main Navigation">
      <div className="Navbar-container">
        <div className="hamburger-container">
          <button 
            className="hamburger-button" 
            onClick={toggleMenu}
            aria-expanded={menuOpen}
            aria-label="Toggle navigation menu"
          >
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </button>
        </div>
        
        <ul className={menuOpen ? "nav-menu open" : "nav-menu"}>
          <li><Link to="/" onClick={() => setMenuOpen(false)}>Home</Link></li>
          <li><Link to="/screen-printing" onClick={() => setMenuOpen(false)}>Screen Printing</Link></li>
          <li><Link to="/digital-printing" onClick={() => setMenuOpen(false)}>Digital Printing</Link></li>
          <li><Link to="/about" onClick={() => setMenuOpen(false)}>About</Link></li>
          <li><Link to="/quote-generator" className="quote-button" onClick={() => setMenuOpen(false)}>Get a Quote</Link></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;