import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { localNewsArticles, getRandomBusinessArticles } from '../data/localNewsArticles';
import '../styling/LocalNews.css';

const LocalNews = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentArticle, setCurrentArticle] = useState(null);
  const [relatedArticles, setRelatedArticles] = useState([]);

  useEffect(() => {
    // Get article ID from URL hash
    const articleId = location.hash.replace('#', '');
    const article = localNewsArticles.find(a => a.id === articleId);
    setCurrentArticle(article || localNewsArticles[0]); // Default to first article if none found
    setRelatedArticles(getRandomBusinessArticles(3));
  }, [location]);

  if (!currentArticle) return null;

  return (
    <div className="LocalNewsPage">
      <div className="Masthead">
        <h1>LOCAL NEWS & HAPPENINGS</h1>
        <p className="Subtitle">The Latest from Eight Seven Central Times</p>
      </div>

      <article className="MainArticle Feature">
        <h2>{currentArticle.headline}</h2>
        <p className="Dateline">{currentArticle.dateline}</p>
        <div className="ArticleContent">
          {currentArticle.fullText}
        </div>
      </article>

      <div className="NavigationArea">
        <button 
          onClick={() => navigate(-1)} 
          className="BackButton"
        >
          ← Back to Previous Page
        </button>
      </div>

      <div className="RelatedArticles">
        <h3 className="SectionHeader">RELATED STORIES</h3>
        <div className="ArticleGrid">
          {relatedArticles.map(article => (
            <article key={article.id} className="Feature span-1">
              <h4>{article.headline}</h4>
              <p>{article.excerpt}</p>
              <Link to={article.link} className="ReadMore">
                {article.callToAction}
              </Link>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LocalNews; 