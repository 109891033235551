import React, { useState, useEffect, useMemo, useCallback, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styling/QuoteGenerator.css';
import MaterialSelection from '../components/MaterialSelection';
import ColorSelection from '../components/ColorSelection';
import QuantityGrid from '../components/QuantityGrid';
import PrintLocations from '../components/PrintLocations';
import ArtworkUpload from '../components/ArtworkUpload';
import DueDateSelection from '../components/DueDateSelection';
import DeliveryMethod from '../components/DeliveryMethod';
import FloatingQuote from '../components/FloatingQuote';
import { calculatePricing } from '../utils/priceCalculator';
import { calculateDefaultDate, calculateRushStatus } from '../utils/dateUtils';
import { debounce } from 'lodash';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATE_AND_RUSH':
      return {
        ...state,
        deliveryDate: action.payload.date,
        isRushOrder: action.payload.isRush
      };
    case 'SET_MATERIAL':
      return {
        ...state,
        material: action.material
      };
    case 'SET_QUANTITIES':
      return {
        ...state,
        quantities: action.quantities
      };
    case 'SET_PRINT_LOCATIONS':
      return {
        ...state,
        printLocations: action.printLocations
      };
    case 'SET_DESIGNS':
      return {
        ...state,
        designs: action.designs
      };
    case 'SET_DELIVERY_ADDRESS':
      return {
        ...state,
        deliveryAddress: action.address
      };
    case 'SET_ADDITIONAL_NOTES':
      return {
        ...state,
        additionalNotes: action.notes
      };
    case 'SET_ARTWORK':
      return {
        ...state,
        artworkData: action.artwork
      };
    case 'SET_PRICING':
      return {
        ...state,
        pricing: action.pricing
      };
    case 'SET_DISCOUNTED_PRICING':
      return {
        ...state,
        discountedPricing: action.discountedPricing
      };
    case 'SET_BOTH_PRICING':
      return {
        ...state,
        pricing: action.pricing,
        discountedPricing: action.discountedPricing
      };
    case 'RESTORE_ALL':
      return {
        ...action.state
      };
    case 'SET_DELIVERY_METHOD':
      return {
        ...state,
        deliveryMethod: action.method
      };
    default:
      return state;
  }
};

const QuoteGenerator = () => {
  const navigate = useNavigate();

  // Use the imported calculateDefaultDate
  const [state, dispatch] = useReducer(reducer, {
    deliveryDate: calculateDefaultDate(),
    isRushOrder: false,
    material: null,
    quantities: {
      XS: 0, S: 0, M: 0, L: 0, XL: 0, '2XL': 0, '3XL': 0
    },
    printLocations: {
      frontLarge: false,
      frontSmall: false,
      backLarge: false,
      backSmall: false,
      leftSleeve: false,
      rightSleeve: false
    },
    designs: [],
    deliveryMethod: 'pickup',
    deliveryAddress: {
      name: '',
      company: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      phone: ''
    },
    additionalNotes: '',
    artworkData: null
  });

  // Calculate total quantity
  const totalQuantity = Object.values(state.quantities).reduce((sum, qty) => sum + Number(qty), 0);

  // Move this BEFORE the pricing calculation
  const hasSelectedPrintLocation = state.printLocations && 
    Object.values(state.printLocations).some(selected => selected === true);

  // Add this before the debouncedPricing
  const updateDateAndRushStatus = useCallback((newDate) => {
    if (!newDate) return;
    const rushStatus = calculateRushStatus(newDate);
    dispatch({ 
      type: 'SET_DATE_AND_RUSH', 
      payload: { 
        date: newDate, 
        isRush: rushStatus.isRush 
      } 
    });
  }, []);

  // Modify the pricing calculation to be more selective
  const debouncedPricing = useCallback(
    debounce((deps) => {
      const { totalQty, material, hasLocation, locations, isRush, method, qtys } = deps;
      if (!totalQty || !material || !hasLocation) {
        dispatch({ type: 'SET_PRICING', pricing: null });
        dispatch({ type: 'SET_DISCOUNTED_PRICING', discountedPricing: null });
        return;
      }
      
      try {
        const basePrice = calculatePricing(
          material,
          qtys,
          locations,
          isRush,
          method
        );
        
        if (!basePrice) return;
        
        // Batch these updates together
        dispatch({ 
          type: 'SET_BOTH_PRICING', 
          pricing: basePrice,
          discountedPricing: {
            ...basePrice,
            discount: basePrice.subtotal * 0.1,
            total: basePrice.total - (basePrice.subtotal * 0.1)
          }
        });
      } catch (error) {
        console.error('Error calculating price:', error);
      }
    }, 250),
    []
  );

  // Move pricing dependencies to useMemo
  const pricingDeps = useMemo(() => ({
    totalQty: totalQuantity,
    material: state.material,
    hasLocation: hasSelectedPrintLocation,
    locations: state.printLocations,
    isRush: state.isRushOrder,
    method: state.deliveryMethod,
    qtys: state.quantities
  }), [
    totalQuantity,
    state.material,
    hasSelectedPrintLocation,
    state.printLocations,
    state.isRushOrder,
    state.deliveryMethod,
    state.quantities
  ]);

  // Update the pricing effect
  useEffect(() => {
    if (pricingDeps.totalQty > 0 && pricingDeps.material && pricingDeps.hasLocation) {
      debouncedPricing(pricingDeps);
    }
  }, [pricingDeps, debouncedPricing]);

  // Handle quote submission
  const handleQuoteSubmission = (quoteData) => {
    const { name, email } = quoteData;
    
    const quoteDetails = {
      name,
      email,
      material: state.material,
      selectedColor: state.material?.selectedColor,
      totalQuantity,
      quantities: state.quantities,
      printLocations: state.printLocations,
      artwork: state.artworkData,
      deliveryDate: state.deliveryDate,
      isRushOrder: state.isRushOrder,
      deliveryMethod: state.deliveryMethod,
      deliveryAddress: state.deliveryAddress,
      pricing: state.pricing
    };

    // Use sessionStorage instead of navigation state
    sessionStorage.setItem('quoteDetails', JSON.stringify(quoteDetails));
    navigate('/quote-confirmation');
  };

  // Handle discounted checkout
  const handleDiscountedCheckout = useCallback(() => {
    const formState = {
      material: state.material,
      quantities: state.quantities,
      printLocations: state.printLocations,
      deliveryDate: state.deliveryDate,
      isRushOrder: state.isRushOrder,
      deliveryMethod: state.deliveryMethod,
      deliveryAddress: state.deliveryAddress,
      artworkData: state.artworkData ? {
        type: state.artworkData.type,
        uploads: Object.fromEntries(
          Object.entries(state.artworkData.uploads || {}).map(([location, data]) => [
            location,
            {
              name: data.name,
              storagePath: data.storagePath,
              previewUrl: data.previewUrl,
              type: data.type,
              size: data.size
            }
          ])
        ),
        description: state.artworkData.description,
        locations: state.artworkData.locations
      } : null,
      pricing: state.pricing,
      discountedPricing: {
        ...state.pricing,
        discount: state.pricing.subtotal * 0.1, // 10% off subtotal
        total: state.pricing.total - (state.pricing.subtotal * 0.1)
      }
    };
    
    sessionStorage.setItem('quoteFormState', JSON.stringify(formState));
    navigate('/checkout');
  }, [state, navigate]);

  // Add this handler before the return statement
  const onMaterialSelect = useCallback((selectedMaterial) => {
    dispatch({
      type: 'SET_MATERIAL',
      material: selectedMaterial
    });
  }, []);

  // Add this function before the return statement
  const handleArtworkUpload = useCallback((artworkData) => {
    if (!artworkData) return;
    
    requestAnimationFrame(() => {
      dispatch({ type: 'SET_ARTWORK', artwork: artworkData });
    });
  }, []);

  // Add this effect at the top level
  useEffect(() => {
    const savedState = sessionStorage.getItem('quoteFormState');
    if (savedState) {
      try {
        const parsedState = JSON.parse(savedState);
        // Use requestAnimationFrame to ensure DOM is ready
        requestAnimationFrame(() => {
          dispatch({ type: 'RESTORE_ALL', state: parsedState });
          // Clear the saved state after restoring
          sessionStorage.removeItem('quoteFormState');
        });
      } catch (error) {
        console.error('Error restoring form state:', error);
      }
    }
  }, []); // Empty dependency array - only runs once

  const handleLocationChange = useCallback((printLocations) => {
    dispatch({ type: 'SET_PRINT_LOCATIONS', printLocations });
  }, []);

  return (
    <div className="quote-generator">
      <h1>Custom Quote Generator</h1>
      
      {/* Material Selection Section */}
      <section className="order-section">
        <h2>1. Select Material</h2>
        <MaterialSelection 
          selectedMaterial={state.material}
          onMaterialSelect={onMaterialSelect}
        />
      </section>
      
      {/* Color Selection Section */}
      <section className="order-section">
        <h2>2. Select Color</h2>
        {state.material && (
          <ColorSelection 
            selectedMaterial={state.material}
            selectedColor={state.material?.selectedColor}
            onColorSelect={(color) => dispatch({ type: 'SET_MATERIAL', material: { ...state.material, selectedColor: color } })}
          />
        )}
      </section>

      {/* Quantity Grid Section */}
      <section className="order-section">
        <h2>3. Unisex Quantities</h2>
        <QuantityGrid 
          quantities={state.quantities}
          onQuantityChange={(quantities) => dispatch({ type: 'SET_QUANTITIES', quantities })}
        />
      </section>

      {/* Print Locations Section */}
      <section className="order-section">
        <h2>4. Print Locations</h2>
        <PrintLocations 
          selectedLocations={state.printLocations}
          onLocationChange={handleLocationChange}
        />
      </section>

      {/* Artwork Upload Section */}
      <section className="order-section">
        <h2>5. Upload Artwork</h2>
        <ArtworkUpload 
          onArtworkUpload={handleArtworkUpload}
          selectedPrintLocations={state.printLocations}
        />
      </section>

      {/* Due Date Selection Section */}
      <section className="order-section">
        <h2>6. Select Due Date</h2>
        <DueDateSelection 
          selectedDate={state.deliveryDate}
          onDateChange={updateDateAndRushStatus}
        />
      </section>

      {/* Delivery Method Section */}
      <section className="order-section">
        <h2>7. Delivery Method</h2>
        <DeliveryMethod 
          selectedMethod={state.deliveryMethod}
          onMethodChange={(method) => {
            console.log('Changing delivery method to:', method);
            dispatch({ type: 'SET_DELIVERY_METHOD', method });
          }}
          shippingAddress={state.deliveryAddress}
          onAddressChange={(address) => dispatch({ type: 'SET_DELIVERY_ADDRESS', address })}
        />
      </section>

      {/* Floating Quote Display */}
      <FloatingQuote 
        material={state.material}
        quantities={state.quantities}
        printLocations={state.printLocations}
        isRushOrder={state.isRushOrder}
        deliveryMethod={state.deliveryMethod}
        onQuoteSubmit={handleQuoteSubmission}
        onPurchaseClick={handleDiscountedCheckout}
        designs={state.designs}
        deliveryDate={state.deliveryDate}
        artworkData={state.artworkData}
        pricing={state.pricing}
        discountedPricing={state.discountedPricing}
      />
    </div>
  );
};

export default QuoteGenerator; 