import React from 'react';
import '../styling/MaterialSelection.css';
import { materials } from '../utils/materialData';

const MaterialSelection = ({ selectedMaterial, onMaterialSelect }) => {
  return (
    <div className="material-selection">
      <div className="material-intro">
        <p>
          We can source about any garment imaginable, but these are some of our best sellers 
          that work for 80% of the printing that we do. Want something else - hoodies, hats, 
          longsleeves? <a href="tel:515-288-2066">Just give us a call</a>!
        </p>
      </div>
      
      <div className="material-cards">
        {materials.map(material => (
          <div 
            key={material.id}
            className={`material-card ${selectedMaterial?.ssStyleId === material.ssStyleId ? 'selected' : ''}`}
            onClick={() => onMaterialSelect(material)}
          >
            <div className="material-header">
              <h3>{material.name}</h3>
              <span className="price-indicator">{material.priceIndicator}</span>
            </div>
            <p className="description">{material.description}</p>
            <div className="specs">
              <p>{material.details.weight}</p>
              <p>{material.details.material}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(MaterialSelection); 