import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../styling/OrderConfirmation.css';

const OrderConfirmation = () => {
  const location = useLocation();
  const { name, email, orderNumber } = location.state || {};

  return (
    <div className="order-confirmation">
      <div className="confirmation-container">
        <div className="confirmation-header">
          <h1>Thank You For Your Order!</h1>
          <div className="order-number">Order #{orderNumber}</div>
        </div>

        <div className="confirmation-content">
          <div className="confirmation-message">
            <p>Hi {name},</p>
            <p>Your order has been received and is being reviewed by our project management team. Here's what happens next:</p>
            
            <div className="next-steps">
              <div className="step">
                <h3>1. Order Review</h3>
                <p>A project manager will review your order within 1 business day to ensure everything is perfect.</p>
              </div>
              
              <div className="step">
                <h3>2. Digital Proof</h3>
                <p>You'll receive a digital mock-up of your design by email, showing exactly how your artwork will look.</p>
              </div>
              
              <div className="step">
                <h3>3. Production</h3>
                <p>Once you approve the mock-up, your order goes into production.</p>
              </div>
            </div>

            <div className="confirmation-details">
              <p>We've sent a confirmation email to: <strong>{email}</strong></p>
              <p>If you have any questions, please contact us at:</p>
              <p><strong>Email:</strong> info@eightsevencentral.com</p>
              <p><strong>Phone:</strong> (941) 867-0287</p>
            </div>
          </div>
        </div>

        <div className="confirmation-actions">
          <Link to="/" className="return-home">Return to Home</Link>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation; 