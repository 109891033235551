import React from 'react';
import '../styling/About.css';

const About = () => {
  return (
    <div className="AboutPage">
      <div className="Masthead">
        <h1>THE STORY OF EIGHT SEVEN CENTRAL</h1>
        <p className="Dateline">Est. 2010 - A Print Studio Obsessed with Perfection</p>
      </div>

      <div className="MainStory Feature">
        <h3>SCREEN PRINTING GEEKS</h3>
        <p className="LeadParagraph">
          Eight Seven Central is a print studio obsessed with creating the perfect shirt for every occasion.
        </p>
        
        <div className="StoryContent">
          <p>
            We believe that a perfectly printed shirt that arrives a day too late is not a perfectly printed shirt. 
            We understand that a perfectly printed shirt that arrives on time but over budget is still not a perfectly printed shirt. 
            And on top of that, we understand that if it looks like sh*t, it isn't perfect either.
          </p>

          <div className="Feature">
            <h3>THE PURSUIT OF PERFECTION</h3>
            <p>
              Baked into our DNA is an obsession with details, hard work, and experimentation in order to find the best ways to print and produce quality work. 
              Over the years we have tweaked or overhauled our workflow, our desk setup, our shop management system, screen reclamation, 
              and tested out all kinds of inks and print methods.
            </p>
            <p>
              You will often find us at trade shows, discovering new apparel brands and machinery. 
              This year we even built a temperature, dust, and humidity controlled zone in order to get the best possible prints from our Direct To Garment printer.
            </p>
          </div>

          <div className="Feature">
            <h3>OUR JOURNEY</h3>
            <p>
              In the early days, we existed in the East Village district of Des Moines, IA. That space was fraught with cardboard cuts on our legs from open box flaps and fumes from cleaning screens. 
              Today, we own a building in the Roosevelt Historic District and truly feel that our ability to produce quality shirts at volume is second to none.
            </p>
          </div>

          <div className="Feature">
            <h3>WORKING WITH US</h3>
            <p className="PartnershipIntro">
              At Eight Seven Central, we don't just print shirts – we build lasting partnerships.
            </p>
            <p>
              We take our clients' success personally. Whether you're organizing a community event, launching a new brand, or outfitting your growing business, your goals become our goals. This dedication to partnership means we constantly go above and beyond for clients we love – troubleshooting last-minute issues, expediting orders when real life happens, and bringing creative solutions to the table that you may not have considered.
            </p>
            <p>
              Many of our clients have been with us for many years, returning season after season because they know we'll deliver not just quality products, but a seamless experience. We celebrate long-term relationships where we truly understand your brand, preferences, and needs. When you work with Eight Seven Central, you're not just another order number – you're joining a community that values your success as much as our own.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About; 