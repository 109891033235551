import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import './ShirtColorSelector.css';

const ShirtColorSelector = ({ selectedColor, onColorSelect }) => {
  const [availableColors, setAvailableColors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fixed to Bella+Canvas 3001
  const STYLE_ID = '00606';

  useEffect(() => {
    const loadColors = async () => {
      setLoading(true);
      setError(null);
      
      try {
        console.log('Fetching colors from Firestore for Bella+Canvas 3001');
        const colorDoc = await getDoc(doc(db, 'colorSwatches', STYLE_ID));
        
        if (colorDoc.exists()) {
          const colorData = colorDoc.data();
          const colors = colorData.colors
            .map((color, index) => ({
              id: `${STYLE_ID}-${color.name}-${index}`,
              name: color.name,
              swatchUrl: color.swatchUrl,
              hex: color.swatchTextColor
            }))
            .sort((a, b) => a.name.localeCompare(b.name));
          setAvailableColors(colors);
        } else {
          console.log('No colors found for Bella+Canvas 3001');
          setAvailableColors([]);
        }
      } catch (err) {
        console.error('Error loading colors:', err);
        setError('Failed to load color options');
      } finally {
        setLoading(false);
      }
    };

    loadColors();
  }, []);

  if (loading) {
    return (
      <div className="shirt-color-selector-loading">
        <p>Loading available colors...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="shirt-color-selector-error">
        <p>Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="shirt-color-selector">
      <div className="color-grid horizontal">
        {availableColors.map(color => (
          <button
            key={color.id}
            className={`color-swatch ${selectedColor?.id === color.id ? 'selected' : ''}`}
            onClick={() => onColorSelect(color)}
            style={{ 
              backgroundImage: `url(${color.swatchUrl})`,
              backgroundColor: color.hex 
            }}
            title={color.name}
          >
            <span className="color-name">{color.name}</span>
          </button>
        ))}
      </div>
      
      {selectedColor && (
        <div className="selected-color-info">
          <p>Selected: {selectedColor.name}</p>
        </div>
      )}
    </div>
  );
};

export default ShirtColorSelector; 