import { getFunctions, httpsCallable } from 'firebase/functions';

// Base URL for your Firebase Functions
const API_BASE = 'https://us-central1-eightseven-website.cloudfunctions.net/api';

// Create a payment intent (authorization)
export const createPaymentIntent = async (amount, metadata) => {
  try {
    // Add validation
    if (!amount || amount <= 0) {
      throw new Error(`Invalid amount: ${amount}`);
    }

    // Initialize Firebase Functions
    const functions = getFunctions();
    
    // Create callable function reference
    const createPaymentIntentFn = httpsCallable(functions, 'createPaymentIntent');
    
    // Enhanced logging
    console.log('Creating payment intent with:', {
      amount,
      metadata,
      timestamp: new Date().toISOString()
    });
    
    // Call the function with the required parameters
    const result = await createPaymentIntentFn({ 
      amount, 
      metadata 
    });
    
    if (!result.data || !result.data.clientSecret) {
      console.error('Invalid payment intent response:', result);
      throw new Error('Invalid payment intent response');
    }
    
    console.log('Payment intent created successfully:', {
      success: true,
      hasClientSecret: !!result.data.clientSecret,
      timestamp: new Date().toISOString()
    });
    
    return result.data;
  } catch (error) {
    console.error('Payment intent creation failed:', {
      error: error.message,
      code: error.code,
      details: error.details,
      timestamp: new Date().toISOString()
    });
    throw error;
  }
};

// Save order details
export const saveOrder = async (paymentIntentId, orderDetails, contactInfo) => {
  try {
    // Initialize Firebase Functions
    const functions = getFunctions();
    
    // Create callable function reference
    const saveOrderFn = httpsCallable(functions, 'saveOrder');
    
    // Call the function with the required parameters
    const result = await saveOrderFn({ 
      paymentIntentId, 
      order: {
        ...orderDetails,
        contactInfo,
        paymentIntentId,
        orderDate: new Date().toISOString()
      }
    });
    
    return result.data;
  } catch (error) {
    console.error('Order save failed:', error);
    throw error;
  }
};

// Send order confirmation email to customer
export const sendOrderConfirmationEmail = async (orderData) => {
  try {
    console.log('=== SENDING CONFIRMATION EMAIL ===');
    console.log('1. Raw orderData:', JSON.stringify(orderData, null, 2));
    
    const functions = getFunctions();
    const sendOrderConfirmationFn = httpsCallable(functions, 'sendOrderConfirmation');
    
    // Format the data for the email
    const emailData = {
      customer: {
        name: orderData.contactInfo.fullName,
        email: orderData.contactInfo.email,
        phone: orderData.contactInfo.phone,
        notes: orderData.contactInfo.orderNotes
      },
      order: {
        orderNumber: orderData.orderNumber,
        material: orderData.orderDetails.material,
        quantities: orderData.orderDetails.quantities,
        printLocations: orderData.orderDetails.printLocations,
        designs: orderData.orderDetails.designs,
        deliveryMethod: orderData.orderDetails.deliveryMethod,
        deliveryAddress: orderData.orderDetails.deliveryAddress,
        deliveryDate: orderData.orderDetails.deliveryDate,
        pricing: {
          perItem: orderData.orderDetails.pricing?.perItem || 0,
          subtotal: orderData.orderDetails.pricing?.subtotal || 0,
          rushFee: orderData.orderDetails.pricing?.rushFee || 0,
          shippingCost: orderData.orderDetails.pricing?.shippingCost || 0,
          discount: orderData.orderDetails.pricing?.discount || 0,
          salesTax: orderData.orderDetails.pricing?.salesTax || 0,
          total: orderData.orderDetails.pricing?.total || 0
        }
      }
    };
    
    console.log('2. Formatted email data:', JSON.stringify(emailData, null, 2));
    const result = await sendOrderConfirmationFn(emailData);
    console.log('3. Email function result:', result);
    
    return result.data;
  } catch (error) {
    console.error('Failed to send confirmation email:', {
      error: error.message,
      code: error.code,
      details: error.details,
      stack: error.stack,
      orderData: JSON.stringify(orderData, null, 2)
    });
    throw error;
  }
};

// Notify admin of new order
export const notifyAdminOfOrder = async (orderData) => {
  try {
    console.log('=== SENDING ADMIN NOTIFICATION ===');
    console.log('1. Raw orderData:', JSON.stringify(orderData, null, 2));
    
    const functions = getFunctions();
    const notifyAdminNewOrderFn = httpsCallable(functions, 'notifyAdminNewOrder');
    
    // Format the data for the email
    const adminNotificationData = {
      customer: {
        fullName: orderData.contactInfo.fullName,
        email: orderData.contactInfo.email,
        phone: orderData.contactInfo.phone,
        notes: orderData.contactInfo.orderNotes
      },
      order: {
        orderNumber: orderData.orderNumber,
        material: orderData.orderDetails.material,
        quantities: orderData.orderDetails.quantities,
        printLocations: orderData.orderDetails.printLocations,
        designs: orderData.orderDetails.designs,
        deliveryMethod: orderData.orderDetails.deliveryMethod,
        deliveryAddress: orderData.orderDetails.deliveryAddress,
        deliveryDate: orderData.orderDetails.deliveryDate,
        pricing: {
          perItem: orderData.orderDetails.pricing?.perItem || 0,
          subtotal: orderData.orderDetails.pricing?.subtotal || 0,
          rushFee: orderData.orderDetails.pricing?.rushFee || 0,
          shippingCost: orderData.orderDetails.pricing?.shippingCost || 0,
          discount: orderData.orderDetails.pricing?.discount || 0,
          salesTax: orderData.orderDetails.pricing?.salesTax || 0,
          total: orderData.orderDetails.pricing?.total || 0
        }
      }
    };
    
    console.log('2. Formatted admin notification data:', JSON.stringify(adminNotificationData, null, 2));
    const result = await notifyAdminNewOrderFn(adminNotificationData);
    console.log('3. Admin notification result:', result);
    
    return result.data;
  } catch (error) {
    console.error('Failed to send admin notification:', {
      error: error.message,
      code: error.code,
      details: error.details,
      stack: error.stack,
      orderData: JSON.stringify(orderData, null, 2)
    });
    throw error;
  }
};

// Send admin notification about a new order (independent of payment)
export const sendAdminOrderNotification = async (orderData) => {
  try {
    console.log('=== SENDING INDEPENDENT ADMIN NOTIFICATION ===');
    console.log('Order data for admin notification:', JSON.stringify(orderData, null, 2));
    
    const functions = getFunctions();
    const notifyAdminNewOrderFn = httpsCallable(functions, 'notifyAdminNewOrder');
    
    // Format the data for the email
    const adminNotificationData = {
      customer: {
        fullName: orderData.contactInfo.fullName,
        email: orderData.contactInfo.email,
        phone: orderData.contactInfo.phone,
        notes: orderData.contactInfo.orderNotes
      },
      order: {
        orderNumber: orderData.orderNumber || `INQUIRY-${Date.now()}`,
        material: orderData.orderDetails.material,
        quantities: orderData.orderDetails.quantities,
        printLocations: orderData.orderDetails.printLocations,
        designs: orderData.orderDetails.designs,
        deliveryMethod: orderData.orderDetails.deliveryMethod,
        deliveryAddress: orderData.orderDetails.deliveryAddress,
        deliveryDate: orderData.orderDetails.deliveryDate,
        pricing: {
          perItem: orderData.orderDetails.pricing?.perItem || 0,
          subtotal: orderData.orderDetails.pricing?.subtotal || 0,
          rushFee: orderData.orderDetails.pricing?.rush || 0,
          shippingCost: orderData.orderDetails.pricing?.shipping || 0,
          salesTax: orderData.orderDetails.pricing?.tax || 0,
          discount: orderData.orderDetails.pricing?.discount || 0,
          total: orderData.orderDetails.pricing?.total || 0
        },
        paymentStatus: orderData.paymentIntent?.status || 'quote_only'
      }
    };
    
    console.log('Admin notification data:', JSON.stringify(adminNotificationData, null, 2));
    const result = await notifyAdminNewOrderFn(adminNotificationData);
    console.log('Admin notification result:', result);
    
    return result.data;
  } catch (error) {
    console.error('Failed to send admin notification:', error);
    throw error;
  }
}; 