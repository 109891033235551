import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../styling/QuoteConfirmation.css';

const QuoteConfirmation = () => {
  const location = useLocation();
  const { name } = location.state || { name: 'there' };

  return (
    <div className="confirmation-container">
      <div className="confirmation-content">
        <h1>Thank You, {name}!</h1>
        
        <div className="confirmation-message">
          <p>We've received your quote request and will be in touch soon.</p>
          <p>One of our experienced team members will review your project details and respond within one business day with pricing options and recommendations.</p>
        </div>
        
        <div className="what-happens-next">
          <h2>What Happens Next?</h2>
          <ol>
            <li>Our team will review your project details</li>
            <li>We'll prepare a custom quote based on your specific needs</li>
            <li>You'll receive an email with pricing and options</li>
            <li>We'll follow up to answer any questions and help you get started</li>
          </ol>
        </div>
        
        <div className="contact-info">
          <h2>Have Questions?</h2>
          <p>Feel free to contact us directly:</p>
          <p><strong>Phone:</strong> <a href="tel:515-288-2066">515-288-2066</a></p>
          <p><strong>Email:</strong> <a href="mailto:info@eightsevencentral.com">info@eightsevencentral.com</a></p>
          <p><strong>Hours:</strong> 9:30-5:00 Weekdays</p>
        </div>
        
        <div className="navigation-links">
          <Link to="/" className="home-link">Return to Home</Link>
          <Link to="/screen-printing" className="services-link">View Our Services</Link>
        </div>
      </div>
    </div>
  );
};

export default QuoteConfirmation; 