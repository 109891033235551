import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styling/GetQuote.css';
import { getFunctions, httpsCallable } from 'firebase/functions';

const GetQuote = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    quantity: '',
    pickupDate: '',
    projectDetails: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    try {
      // Get Firebase functions
      const functions = getFunctions();
      
      // Create a reference to the quote request function
      const sendQuoteRequestFn = httpsCallable(functions, 'sendQuoteRequest');
      
      // Call the function with form data
      const result = await sendQuoteRequestFn(formData);
      
      console.log('Quote request result:', result.data);
      
      // Redirect to confirmation page
      navigate('/quote-confirmation', { state: { name: formData.name } });
    } catch (err) {
      console.error('Error sending quote request:', err);
      setError('There was a problem submitting your request. Please try again or contact us directly.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="get-quote-container">
      <div className="quote-content">
        <h1>Get a Free Quote</h1>
        <p className="intro-text">
          Tell us about your project, and we'll get back to you with pricing and options within one business day.
        </p>

        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleSubmit} className="quote-form">
          <div className="form-group">
            <label htmlFor="name">Name *</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="quantity">Approximate Number of Shirts *</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              min="1"
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="pickupDate">Desired Pickup Date</label>
            <input
              type="date"
              id="pickupDate"
              name="pickupDate"
              value={formData.pickupDate}
              onChange={handleChange}
              min={new Date().toISOString().split('T')[0]}
            />
          </div>

          <div className="form-group">
            <label htmlFor="projectDetails">Tell us about your project *</label>
            <textarea
              id="projectDetails"
              name="projectDetails"
              value={formData.projectDetails}
              onChange={handleChange}
              rows="5"
              required
              placeholder="Please include details about colors, print locations, and any other specifics that would help us provide an accurate quote."
            ></textarea>
          </div>

          <button 
            type="submit" 
            className="submit-button"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending...' : 'Request Quote'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default GetQuote; 