import React from 'react';
import { Link } from 'react-router-dom';
import '../styling/CustomTshirtsLanding.css';
import garmentWall from '../assets/images/garment-wall.jpg';
// Import any additional images you want to use

const CustomTshirtsLanding = () => {
  return (
    <div className="custom-tshirts-landing">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Custom T-Shirts Des Moines</h1>
        <p className="hero-subtitle">Professional T-Shirt Printing in the Heart of Des Moines</p>
        <div className="hero-cta">
          <Link to="/quote-generator" className="cta-button">Get Your Custom Quote Today</Link>
        </div>
      </section>

      {/* Quick Info Section */}
      <section className="quick-info">
        <div className="info-card">
          <h3>Local Pickup</h3>
          <p>863 42nd Street, Des Moines</p>
        </div>
        <div className="info-card">
          <h3>Fast Turnaround</h3>
          <p>Ready in 8 Business Days</p>
        </div>
        <div className="info-card">
          <h3>Minimum Order</h3>
          <p>Just 12 Shirts</p>
        </div>
      </section>

      {/* Services Grid */}
      <section className="services-grid">
        <h2>T-Shirt Printing Services in Des Moines</h2>
        <div className="services-container">
          <div className="service-card">
            <h3>Screen Printing</h3>
            <ul>
              <li>Best for 12+ shirts</li>
              <li>Vibrant, durable prints</li>
              <li>Team uniforms & events</li>
              <li>Bulk order discounts</li>
            </ul>
            <span className="price-indicator">From $6.00/shirt</span>
          </div>

          <div className="service-card">
            <h3>Digital Printing</h3>
            <ul>
              <li>Perfect for 1-24 shirts</li>
              <li>Full color designs</li>
              <li>Photo-quality prints</li>
              <li>No setup fees</li>
            </ul>
            <span className="price-indicator">From $16.00/shirt</span>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="why-choose-us">
        <h2>Why Choose Eight Seven Central for Custom T-Shirts?</h2>
        <div className="benefits-grid">
          <div className="benefit">
            <h3>🏢 Local Des Moines Business</h3>
            <p>Serving our community since 2010 with quality custom shirts</p>
          </div>
          <div className="benefit">
            <h3>👕 Premium Materials</h3>
            <p>High-quality blanks from Bella+Canvas, Next Level, and AS Colour</p>
          </div>
          <div className="benefit">
            <h3>🎨 Free Design Help</h3>
            <p>Professional designers to help perfect your artwork</p>
          </div>
          <div className="benefit">
            <h3>⭐ Proven Track Record</h3>
            <p>Thousands of satisfied customers across Central Iowa</p>
          </div>
        </div>
      </section>

      {/* Recent Projects */}
      <section className="recent-projects">
        <h2>Recent Des Moines T-Shirt Projects</h2>
        <div className="projects-grid">
          {/* Add 3-4 example projects with images */}
        </div>
      </section>

      {/* FAQ Section */}
      <section className="faq-section">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-grid">
          <div className="faq-item">
            <h3>What's your minimum order quantity?</h3>
            <p>Our minimum order is 12 shirts for screen printing and 1 shirt for digital printing.</p>
          </div>
          <div className="faq-item">
            <h3>How long does it take to get custom shirts in Des Moines?</h3>
            <p>Standard turnaround is 8 business days, with rush options available for faster delivery.</p>
          </div>
          <div className="faq-item">
            <h3>Do you offer local pickup in Des Moines?</h3>
            <p>Yes! Pick up your order at our convenient Des Moines location on 42nd Street.</p>
          </div>
          <div className="faq-item">
            <h3>What type of artwork do you need?</h3>
            <p>We accept most file types and offer free design services to help perfect your artwork.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="final-cta">
        <h2>Ready to Order Custom T-Shirts in Des Moines?</h2>
        <p>Get started with your custom t-shirt order today!</p>
        <div className="cta-buttons">
          <Link to="/quote-generator" className="cta-button">Get an Instant Quote</Link>
          <a href="tel:6513873661" className="cta-button secondary">Call Us: (515) 288-2066</a>
        </div>
      </section>
    </div>
  );
};

export default CustomTshirtsLanding; 