import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { materials } from '../utils/materialData';
import '../styling/GarmentInventoryDashboard.css';
import { onSnapshot } from 'firebase/firestore';
import { collection, getDocs } from 'firebase/firestore';
import { syncStyleInventory } from '../utils/ssActivewearSync';
import { getColorSwatches } from '../services/api';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytes } from 'firebase/storage';

const GarmentInventoryDashboard = () => {
  const [inventoryData, setInventoryData] = useState({});
  const [lastUpdated, setLastUpdated] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState('checking');
  const [testMessage, setTestMessage] = useState(null);
  const [error, setError] = useState(null);
  const [garments, setGarments] = useState({});
  const [syncStatus, setSyncStatus] = useState('');
  const [lastSyncTime, setLastSyncTime] = useState(null);
  const [colorSwatches, setColorSwatches] = useState({});
  const [colorUpdateStatus, setColorUpdateStatus] = useState('');
  const [isUpdatingColors, setIsUpdatingColors] = useState(false);
  const [imageUpdateStatus, setImageUpdateStatus] = useState('');
  const [isUpdatingImages, setIsUpdatingImages] = useState(false);
  const [emailTestStatus, setEmailTestStatus] = useState('');
  const [isTestingEmail, setIsTestingEmail] = useState(false);

  const fetchInventoryData = async () => {
    setIsLoading(true);
    try {
      const garmentData = {};
      
      // Fetch data for each material style
      for (const material of materials) {
        const garmentDoc = await getDoc(doc(db, "garments", material.ssStyleId));
        
        if (garmentDoc.exists()) {
          const data = garmentDoc.data();
          console.log(`Fetched data for ${material.name}:`, data);
          
          // Store the data using the styleId as the key
          garmentData[material.ssStyleId] = {
            name: material.name,
            lastUpdated: data.lastUpdated,
            inventory: data.inventory
          };
        }
      }
      
      setInventoryData(garmentData);
      // Set the last updated time from the most recent update
      const mostRecent = Object.values(garmentData)
        .map(g => g.lastUpdated)
        .sort((a, b) => b - a)[0];
      setLastUpdated(mostRecent);
      
    } catch (error) {
      console.error("Error fetching inventory:", error);
    }
    setIsLoading(false);
  };

  const testConnection = async () => {
    console.log("Testing Firebase connection...");
    try {
      // Try to write a test document
      const testDocRef = doc(db, "appData", "test");
      await setDoc(testDocRef, {
        timestamp: new Date(),
        test: 'Connection test'
      });
      console.log("Successfully wrote test document");

      // Try to read it back
      const testDoc = await getDoc(testDocRef);
      console.log("Firestore response:", {
        success: true,
        exists: testDoc.exists(),
        data: testDoc.data(),
        metadata: testDoc.metadata
      });
      
      setConnectionStatus('connected');
    } catch (error) {
      console.error("Firebase connection error details:", {
        code: error.code,
        message: error.message,
        stack: error.stack,
        name: error.name,
        cause: error.cause
      });
      setConnectionStatus('error');
    }
  };

  const testSync = async () => {
    try {
      setSyncStatus('Syncing...');
      
      // Test sync for all materials
      for (const material of materials) {
        console.log(`Syncing ${material.name}...`);
        await syncStyleInventory(material.ssStyleId);
      }
      
      // Refresh the display
      await fetchGarments();
      setLastSyncTime(new Date().toLocaleString());
      setSyncStatus('Sync completed successfully!');
    } catch (error) {
      console.error('Sync failed:', error);
      setSyncStatus(`Sync failed: ${error.message}`);
    }
  };

  const fetchGarments = async () => {
    try {
      setIsLoading(true);
      const garmentsCollection = collection(db, 'garments');
      const garmentsSnapshot = await getDocs(garmentsCollection);
      
      const garmentsData = {};
      garmentsSnapshot.forEach(doc => {
        garmentsData[doc.id] = doc.data();
      });
      
      console.log('Fetched Firestore data:', garmentsData);
      setGarments(garmentsData);
      setError(null);
    } catch (err) {
      console.error('Error fetching garments:', err);
      setError('Failed to load garments');
    } finally {
      setIsLoading(false);
    }
  };

  const handleColorSwatchUpdate = async () => {
    setIsUpdatingColors(true);
    setColorUpdateStatus('Updating color swatches...');
    
    try {
      // Get the region-specific URL
      const functionUrl = 'https://us-central1-eightseven-website.cloudfunctions.net/getProductColors';
      
      // Test with just one style ID
      const testStyle = "00760"; // Budget Cotton
      console.log('Testing with single style:', testStyle);
      
      const response = await fetch(functionUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            styleIds: [testStyle]
          }
        })
      });

      const result = await response.json();
      console.log('Function Response:', result);
      
      if (!response.ok) {
        throw new Error(result.error || 'Failed to update colors');
      }
      
      await fetchColorSwatches();
      setColorUpdateStatus('Color swatches updated successfully!');
    } catch (error) {
      console.error('Full error:', error);
      setColorUpdateStatus(`Error: ${error.message}`);
    } finally {
      setIsUpdatingColors(false);
    }
  };

  const fetchColorSwatches = async () => {
    try {
      const swatchData = {};
      
      for (const material of materials) {
        console.log(`Fetching colors for material: ${material.name} (${material.ssStyleId})`);
        const swatchDoc = await getDoc(doc(db, "colorSwatches", material.ssStyleId));
        
        if (swatchDoc.exists()) {
          const data = swatchDoc.data();
          console.log('Raw Firestore data for', material.ssStyleId, ':', {
            totalColors: data.colors?.length,
            uniqueColorNames: new Set(data.colors?.map(c => c.name)).size,
            allColorNames: data.colors?.map(c => c.name)
          });
          swatchData[material.ssStyleId] = data.colors || [];
        }
      }
      
      setColorSwatches(swatchData);
    } catch (error) {
      console.error('Error fetching color swatches:', error);
      setError('Failed to fetch color swatches');
    }
  };

  const handleImageUpdate = async () => {
    setIsUpdatingImages(true);
    setImageUpdateStatus('Updating product images...');
    
    try {
      for (const material of materials) {
        // Get color data from Firestore
        const swatchDoc = await getDoc(doc(db, "colorSwatches", material.ssStyleId));
        
        if (swatchDoc.exists()) {
          const data = swatchDoc.data();
          
          // For each color, download and store the front image
          for (const color of data.colors) {
            if (color.frontImage) {
              // Create a reference to where we want to store the image
              const storage = getStorage();
              const imageRef = ref(storage, `products/shirts/${material.ssStyleId}/${color.name}-front.jpg`);
              
              // Download the image
              const response = await fetch(color.frontImage);
              const blob = await response.blob();
              
              // Upload to Firebase Storage
              await uploadBytes(imageRef, blob);
              console.log(`Uploaded image for ${material.name} - ${color.name}`);
            }
          }
        }
      }
      
      setImageUpdateStatus('Product images updated successfully!');
    } catch (error) {
      console.error('Error updating images:', error);
      setImageUpdateStatus(`Error: ${error.message}`);
    } finally {
      setIsUpdatingImages(false);
    }
  };

  const handleEmailTest = async () => {
    setIsTestingEmail(true);
    setEmailTestStatus('Sending test emails...');
    
    try {
      // Get functions instance 
      const functions = getFunctions();
      
      // Create callable references
      const sendOrderConfirmationFn = httpsCallable(functions, 'sendOrderConfirmation');
      const notifyAdminFn = httpsCallable(functions, 'notifyAdminNewOrder');
      
      // Test data
      const testData = {
        order: {
          material: { name: "Premium Cotton T-Shirt" },
          color: "Navy Blue",
          totalQuantity: 50,
          pricing: {
            subtotal: 750.00,
            total: 802.50
          },
          sizes: {
            S: 10,
            M: 15,
            L: 15,
            XL: 10
          }
        },
        customer: {
          fullName: "Test Customer",
          email: "ben@eightsevencentral.com", // Use your real email
          phone: "555-123-4567",
          orderNotes: "This is a test order from the admin dashboard."
        },
        orderId: `TEST-${new Date().getTime()}`
      };
      
      // ONLY use the callable approach - remove any fetch code
      setEmailTestStatus('Sending customer confirmation...');
      const confirmResult = await sendOrderConfirmationFn(testData);
      console.log('Customer email result:', confirmResult.data);
      
      setEmailTestStatus('Sending admin notification...');
      const adminResult = await notifyAdminFn(testData);
      console.log('Admin email result:', adminResult.data);
      
      setEmailTestStatus('Email tests completed successfully! Check your inbox.');
    } catch (error) {
      console.error('Error testing emails:', error);
      setEmailTestStatus(`Error: ${error.message}`);
    } finally {
      setIsTestingEmail(false);
    }
  };

  useEffect(() => {
    fetchInventoryData();
    testConnection();
    fetchGarments();
    fetchColorSwatches();
  }, []);

  useEffect(() => {
    fetchColorSwatches();
  }, [colorUpdateStatus]);

  const handleRefresh = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('http://localhost:5000/api/inventory/refresh', { 
        method: 'POST' 
      });
      
      if (!response.ok) {
        throw new Error('Refresh failed');
      }
      
      await fetchInventoryData();
    } catch (error) {
      console.error("Error refreshing data:", error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!db) {
      setError('Firestore not initialized');
      setConnectionStatus('error');
      return;
    }

    console.log('Setting up Firestore listener...');
    
    const docRef = doc(db, 'test', 'connection');
    
    // Set up real-time listener
    const unsubscribe = onSnapshot(docRef, 
      (doc) => {
        console.log('Document snapshot received:', doc.exists() ? 'exists' : 'does not exist');
        if (doc.exists()) {
          setTestMessage(doc.data().message);
          setConnectionStatus('connected');
          setError(null);
        } else {
          setError('Document not found');
          setConnectionStatus('error');
        }
      },
      (err) => {
        console.error('Snapshot listener error:', {
          code: err.code,
          message: err.message,
          name: err.name,
          stack: err.stack
        });
        setError(`Connection error: ${err.message}`);
        setConnectionStatus('error');
      }
    );

    // Cleanup listener on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div className="garment-inventory-dashboard">
      <div className="dashboard-header">
        <h1>Garment Inventory Dashboard</h1>
        <div className="connection-status">
          Firebase Status: 
          {connectionStatus === 'checking' && <span className="status checking"> Checking connection...</span>}
          {connectionStatus === 'connected' && <span className="status connected"> Connected ✓</span>}
          {connectionStatus === 'error' && <span className="status error"> Connection Error ✗</span>}
        </div>
        <div className="dashboard-controls">
          <button 
            onClick={handleRefresh} 
            disabled={isLoading}
            className="refresh-button"
          >
            {isLoading ? 'Refreshing...' : 'Emergency Refresh'}
          </button>
          {lastUpdated && (
            <p className="last-updated">
              Last updated: {lastUpdated.toLocaleString()}
            </p>
          )}
          <button 
            onClick={handleColorSwatchUpdate}
            disabled={isUpdatingColors}
            className="refresh-button"
          >
            {isUpdatingColors ? 'Updating Colors...' : 'Emergency Color Update'}
          </button>
          {colorUpdateStatus && (
            <div className={`status-message ${colorUpdateStatus.includes('Error') ? 'error' : 'success'}`}>
              {colorUpdateStatus}
            </div>
          )}
          <button 
            onClick={handleImageUpdate}
            disabled={isUpdatingImages}
            className="refresh-button"
          >
            {isUpdatingImages ? 'Updating Images...' : 'Update Product Images'}
          </button>
          {imageUpdateStatus && (
            <div className={`status-message ${imageUpdateStatus.includes('Error') ? 'error' : 'success'}`}>
              {imageUpdateStatus}
            </div>
          )}
        </div>
        <button 
          onClick={testConnection}
          className="refresh-button"
        >
          Test Connection
        </button>
        <div className="test-controls">
          <button 
            onClick={handleEmailTest}
            disabled={isTestingEmail}
            className="test-button"
          >
            {isTestingEmail ? 'Sending Test Emails...' : 'Test Order Emails'}
          </button>
          {emailTestStatus && (
            <div className={`status-message ${emailTestStatus.includes('Error') ? 'error' : 'success'}`}>
              {emailTestStatus}
            </div>
          )}
        </div>
      </div>

      <div className="inventory-grid">
        {materials.map(material => (
          <div key={material.id} className="material-section">
            <h2>{material.name}</h2>
            <p className="material-info">Style ID: {material.ssStyleId}</p>
            
            {inventoryData[material.ssStyleId] ? (
              <div className="warehouse-inventory">
                <h3>Warehouse Inventory</h3>
                {inventoryData[material.ssStyleId].inventory[0]?.warehouses.map((warehouse, index) => (
                  <div key={warehouse.warehouseAbbr} className="warehouse-row">
                    <span className="warehouse-name">{warehouse.warehouseAbbr}</span>
                    <span className="warehouse-qty">{warehouse.qty}</span>
                  </div>
                ))}
                <div className="total-inventory">
                  <strong>Total Available: </strong>
                  {inventoryData[material.ssStyleId].inventory[0]?.warehouses.reduce((total, w) => total + w.qty, 0)}
                </div>
              </div>
            ) : (
              <p>No inventory data available</p>
            )}

            {/* Color swatches section */}
            <div className="color-swatches">
              <h3>Available Colors</h3>
              {colorSwatches[material.ssStyleId] ? (
                <div className="swatch-grid">
                  {colorSwatches[material.ssStyleId]
                    .filter(color => color.inStock)  // Only show in-stock colors
                    .map((color, index) => (
                      <div key={`${material.ssStyleId}-${color.name}-${index}`} className="swatch-item">
                        <div 
                          className="swatch-preview"
                          style={{ 
                            backgroundImage: `url(${color.swatchUrl})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            border: '1px solid #ddd',
                            width: '50px',
                            height: '50px',
                            borderRadius: '4px',
                            marginBottom: '4px'
                          }}
                        />
                        <span 
                          className="swatch-name"
                          style={{ 
                            fontSize: '0.875rem',
                            display: 'block',
                            textAlign: 'center'
                          }}
                        >
                          {color.name}
                        </span>
                      </div>
                    ))}
                </div>
              ) : (
                <p>No color data available for {material.name} (Style {material.ssStyleId})</p>
              )}
            </div>
          </div>
        ))}
      </div>

      <div style={{ padding: '20px' }}>
        <h1>Firestore Connection Test</h1>
        <div>Connection Status: {connectionStatus}</div>
        {testMessage && (
          <div style={{ color: 'green', marginTop: '20px' }}>
            Message from Firestore: {testMessage}
          </div>
        )}
        {error && (
          <div style={{ color: 'red', marginTop: '20px' }}>
            Error: {error}
          </div>
        )}
      </div>

      <div className="test-controls">
        <button 
          onClick={testSync}
          disabled={isLoading}
          className="test-button"
        >
          Test Sync (Style 00060)
        </button>
        {syncStatus && (
          <div className={`sync-status ${syncStatus.includes('failed') ? 'error' : 'success'}`}>
            {syncStatus}
          </div>
        )}
        {lastSyncTime && (
          <div className="last-sync-time">
            Last successful sync: {lastSyncTime}
          </div>
        )}
      </div>

      <div className="data-display">
        <h2>Current Firestore Data:</h2>
        {isLoading ? (
          <div>Loading...</div>
        ) : Object.keys(garments).length > 0 ? (
          <pre>
            {JSON.stringify(garments, null, 2)}
          </pre>
        ) : (
          <div>No data found in Firestore</div>
        )}
      </div>
    </div>
  );
};

export default GarmentInventoryDashboard; 