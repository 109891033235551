import React, { useState, useEffect } from 'react';
import '../styling/ArtworkUpload.css';
import { storage } from '../utils/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const ArtworkUpload = ({ onArtworkUpload, selectedPrintLocations = {} }) => {
  const [artworkType, setArtworkType] = useState('complete'); // 'complete', 'sketch', 'description'
  const [fileUploads, setFileUploads] = useState({}); // Map of locationId -> file data
  const [designDescription, setDesignDescription] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  
  // Move this to useMemo
  const locationsToShow = React.useMemo(() => {
    const selected = Object.entries(selectedPrintLocations)
      .filter(([_, isSelected]) => isSelected)
      .map(([location]) => location);
    return selected.length > 0 ? selected : ['frontLarge'];
  }, [selectedPrintLocations]);
  
  // Combine the two effects into one
  useEffect(() => {
    if (!locationsToShow || locationsToShow.length === 0) return;
    
    const currentLocations = Object.keys(fileUploads).sort().join(',');
    const newLocations = locationsToShow.sort().join(',');
    
    if (currentLocations !== newLocations) {
      // Only update fileUploads if locations changed
      const updatedUploads = {};
      Object.entries(fileUploads).forEach(([location, data]) => {
        if (locationsToShow.includes(location)) {
          updatedUploads[location] = data;
        }
      });
      
      // Only update if there are actual changes
      const currentContent = JSON.stringify(fileUploads);
      const newContent = JSON.stringify(updatedUploads);
      
      if (currentContent !== newContent) {
        setFileUploads(updatedUploads);
        
        // Notify parent only if we have uploads
        if (Object.keys(updatedUploads).length > 0) {
          requestAnimationFrame(() => {
            onArtworkUpload({
              type: artworkType,
              uploads: updatedUploads,
              locations: locationsToShow
            });
          });
        }
      }
    }
  }, [locationsToShow, artworkType, onArtworkUpload]); // Remove fileUploads from dependencies

  // Clean up object URLs when component unmounts
  useEffect(() => {
    return () => {
      Object.values(fileUploads).forEach(upload => {
        if (upload.previewUrl && upload.previewUrl.startsWith('blob:')) {
          URL.revokeObjectURL(upload.previewUrl);
        }
      });
    };
  }, [fileUploads]);

  // Add a check for Firebase storage initialization
  useEffect(() => {
    console.log('=== Storage Initialization Check ===');
    console.log('Storage object:', storage);
    
    if (!storage) {
      console.error('Firebase Storage is not initialized!');
    }
  }, []);

  // Add this effect to handle restored artwork data
  useEffect(() => {
    const savedState = sessionStorage.getItem('quoteFormState');
    if (savedState) {
      try {
        const { artworkData } = JSON.parse(savedState);
        if (artworkData?.uploads) {
          // For each upload, get a fresh download URL
          const refreshedUploads = {};
          
          Object.entries(artworkData.uploads).forEach(async ([location, data]) => {
            if (data.storagePath) {
              try {
                const storageRef = ref(storage, data.storagePath);
                const freshUrl = await getDownloadURL(storageRef);
                refreshedUploads[location] = {
                  ...data,
                  previewUrl: freshUrl
                };
                setFileUploads(current => ({
                  ...current,
                  ...refreshedUploads
                }));
              } catch (error) {
                console.error('Error refreshing download URL:', error);
              }
            }
          });

          setArtworkType(artworkData.type || 'complete');
          if (artworkData.description) {
            setDesignDescription(artworkData.description);
          }
        }
      } catch (error) {
        console.error('Error restoring artwork state:', error);
      }
    }
  }, []); // Run once on mount

  const handleArtworkTypeChange = (e) => {
    setArtworkType(e.target.value);
    
    // When changing to description, notify parent component in next tick
    if (e.target.value === 'description') {
      requestAnimationFrame(() => {
        onArtworkUpload({
          type: 'description',
          description: designDescription,
          locations: locationsToShow
        });
      });
    }
  };

  const handleDescriptionChange = (e) => {
    setDesignDescription(e.target.value);
    
    // Update parent component with new description
    if (artworkType === 'description') {
      onArtworkUpload({
        type: 'description',
        description: e.target.value,
        locations: locationsToShow
      });
    }
  };

  const handleDrag = (e, location) => {
    e.preventDefault();
    e.stopPropagation();
    
    const updatedUploads = { ...fileUploads };
    
    if (e.type === "dragenter" || e.type === "dragover") {
      if (!updatedUploads[location]) {
        updatedUploads[location] = { dragActive: true };
      } else {
        updatedUploads[location].dragActive = true;
      }
    } else if (e.type === "dragleave") {
      if (updatedUploads[location]) {
        updatedUploads[location].dragActive = false;
      }
    }
    
    setFileUploads(updatedUploads);
  };

  const handleDrop = (e, location) => {
    e.preventDefault();
    e.stopPropagation();
    
    const updatedUploads = { ...fileUploads };
    if (updatedUploads[location]) {
      updatedUploads[location].dragActive = false;
    }
    setFileUploads(updatedUploads);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0], location);
    }
  };

  const handleChange = (e, location) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0], location);
    }
  };

  const handleFile = async (file, location) => {
    setIsUploading(true);
    try {
      console.log(`=== Starting File Upload Process for ${location} ===`);
      console.log('File details:', {
        name: file.name,
        type: file.type,
        size: file.size
      });
      
      // Create a temporary preview for image files
      let tempPreviewUrl = null;
      if (file.type.startsWith('image/')) {
        tempPreviewUrl = URL.createObjectURL(file);
        console.log('Preview URL created:', tempPreviewUrl);
      }

      // Upload file to Firebase Storage
      const timestamp = Date.now();
      const safeFileName = file.name.replace(/[^a-zA-Z0-9.]/g, '_');
      const storagePath = `artwork/${timestamp}_${location}_${safeFileName}`;
      console.log('Storage path:', storagePath);
      
      try {
        console.log('Creating storage reference...');
        const storageRef = ref(storage, storagePath);
        
        console.log('Starting file upload...');
        const uploadResult = await uploadBytes(storageRef, file);
        console.log('Upload result:', uploadResult);
        
        console.log('Getting download URL...');
        const downloadUrl = await getDownloadURL(storageRef);
        console.log('Download URL:', downloadUrl);

        // Update state and notify parent immediately
        const updatedUploads = {
          ...fileUploads,
          [location]: {
            file: file,
            name: file.name,
            size: file.size,
            type: file.type,
            previewUrl: downloadUrl,
            storagePath: storagePath,
            dragActive: false
          }
        };

        // Add debug log
        console.log('Notifying parent of artwork upload:', {
          type: artworkType,
          uploads: updatedUploads,
          locations: locationsToShow
        });

        // Update state
        setFileUploads(updatedUploads);

        // Notify parent immediately after state update
        onArtworkUpload({
          type: artworkType,
          uploads: updatedUploads,
          locations: locationsToShow
        });

      } catch (storageError) {
        console.error('=== Firebase Storage Error ===');
        console.error('Error details:', {
          message: storageError.message,
          code: storageError.code,
          stack: storageError.stack
        });
        alert(`Storage Error: ${storageError.message}`);
        throw storageError;
      }
      
    } catch (error) {
      console.error('=== Upload Error ===');
      console.error('Error details:', error);
      alert(`Upload failed: ${error.message}`);
    } finally {
      setIsUploading(false);
    }
  };

  // Format location name for display
  const formatLocationName = (location) => {
    return location
      .replace(/([A-Z])/g, ' $1') // Add space before capital letters
      .replace(/^./, str => str.toUpperCase()) // Capitalize first letter
      .trim();
  };

  // First, add the delete handler function near your other handlers
  const handleDeleteFile = (location) => {
    setFileUploads(prevUploads => {
      const updatedUploads = { ...prevUploads };
      delete updatedUploads[location];
      
      // Notify parent of the update
      onArtworkUpload({
        type: artworkType,
        uploads: updatedUploads,
        locations: locationsToShow
      });
      
      return updatedUploads;
    });
  };

  return (
    <div className="artwork-upload">
      <div className="info-column">
        <p className="print-methods-info">
          Based on your artwork, we will determine the best method of printing. Options include:
        </p>
        <ul className="methods-list">
          <li><strong>Screen Printing</strong> - Simple design, large quantity</li>
          <li><strong>Direct to Garment</strong> - Complex design, small to medium quantity</li>
          <li><strong>Film Transfer</strong> - Used on blends, simple or complex, small to medium quantity</li>
        </ul>
      </div>

      <div className="upload-column">
        <div className="artwork-type-selection">
          <label htmlFor="artwork-type">Do you have artwork ready?</label>
          <select 
            id="artwork-type" 
            value={artworkType} 
            onChange={handleArtworkTypeChange}
            className="artwork-type-dropdown"
          >
            <option value="complete">I have completed artwork ready to print</option>
            <option value="sketch">I have a sketch/partial artwork that needs development</option>
            <option value="description">I'll describe the design I want</option>
          </select>
        </div>

        {(artworkType === 'complete' || artworkType === 'sketch') && (
          <div className="upload-locations">
            {locationsToShow.length === 0 ? (
              <p className="no-locations-message">Please select at least one print location in the previous section.</p>
            ) : (
              locationsToShow.map(location => (
                <div key={location} className="location-upload-container">
                  <h4>{formatLocationName(location)}</h4>
                  <div 
                    className={`upload-section ${fileUploads[location]?.dragActive ? 'drag-active' : ''} ${isUploading ? 'uploading' : ''}`}
                    onDragEnter={(e) => handleDrag(e, location)}
                    onDragLeave={(e) => handleDrag(e, location)}
                    onDragOver={(e) => handleDrag(e, location)}
                    onDrop={(e) => handleDrop(e, location)}
                  >
                    <div className="upload-box">
                      <input
                        type="file"
                        id={`artwork-upload-${location}`}
                        className="file-input"
                        onChange={(e) => handleChange(e, location)}
                        accept=".png,.svg,.eps,.ai,.psd,.jpg,.jpeg,.pdf"
                        disabled={isUploading}
                      />
                      <label htmlFor={`artwork-upload-${location}`} className="upload-label">
                        {fileUploads[location]?.name ? (
                          <div className="file-info">
                            {fileUploads[location].previewUrl && fileUploads[location].type.startsWith('image/') && (
                              <div className="file-preview">
                                <img src={fileUploads[location].previewUrl} alt="Artwork preview" />
                              </div>
                            )}
                            <div className="file-details">
                              <span className="file-name">{fileUploads[location].name}</span>
                              <span className="file-size">({(fileUploads[location].size / 1024 / 1024).toFixed(2)} MB)</span>
                            </div>
                            <button 
                              className="delete-file-button" 
                              onClick={(e) => {
                                e.preventDefault(); // Prevent opening file dialog
                                handleDeleteFile(location);
                              }}
                              aria-label="Delete file"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" width="16" height="16">
                                <line x1="18" y1="6" x2="6" y2="18"/>
                                <line x1="6" y1="6" x2="18" y2="18"/>
                              </svg>
                            </button>
                          </div>
                        ) : (
                          <>
                            <div className="upload-icon">
                              {isUploading ? (
                                <div className="loading-spinner">
                                  <div className="upload-progress">
                                    <div className="progress-bar"></div>
                                  </div>
                                  <span className="upload-status-text">Uploading file...</span>
                                </div>
                              ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
                                  <polyline points="17 8 12 3 7 8"/>
                                  <line x1="12" y1="3" x2="12" y2="15"/>
                                </svg>
                              )}
                            </div>
                            <div className="upload-text">
                              {!isUploading && (
                                <>
                                  <p>Drag and drop your artwork here</p>
                                  <span className="upload-subtext">or click to browse files</span>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        )}

        {artworkType === 'description' && (
          <div className="description-container">
            <label htmlFor="design-description">Describe the design you want:</label>
            <textarea
              id="design-description"
              className="design-description-input"
              value={designDescription}
              onChange={handleDescriptionChange}
              placeholder="Please describe your design idea in detail. Include any text, colors, themes, or specific elements you want to include."
              rows={5}
            />
          </div>
        )}
      </div>

      <div className="file-requirements">
        <p className="supported-formats">
          Supported formats: PNG, SVG, EPS, AI, PSD, JPG, PDF
        </p>
        <p className="file-specs">
          Vector Files are Ideal<br />
          Raster Files need to be 300DPI
        </p>
      </div>
    </div>
  );
};

export default ArtworkUpload; 